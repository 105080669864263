.profile {
  padding-top: 60px;

  &__info {

    .input-js {
      .label {
        margin: 0;
        color: $secondary;
        font-weight: 400;

        &.filled {
          font-size: 12px;
          line-height: 14px;
          transform: translateY(-12px);
        }

        &.focused {
          color: $bc;
        }
      }
    }
  }


  &__affiliation {
    max-width: 660px;
    width: 100%;
    padding-bottom: 14px;


    &--personal-information {
      p {
        font-weight: 400;
      }
    }

    &--affiliation-edit {
      width: auto;
      padding-right: 40px;

      @include media-breakpoint-down(sm) {
        padding-right: 0;
        max-width: 40%;
        width: 100%;
      }
    }
  }

  &__affiliation-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    max-width: 500px;
    width: 100%;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }

  &__affiliation-label {
    max-width: 100%;
    width: 100%;

    p {
      font-weight: 500;
      text-align-last: left;

      @include media-breakpoint-down(xs) {
        font-size: 12px;
        line-height: 14px;
        color: #a5a5a5;
        margin-bottom: 10px;
      }
    }
  }

  &__affiliation-info {
    max-width: 100%;
    width: 100%;

    p {
      font-weight: 400;
      text-align-last: left;
    }
  }

  &__input {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 26px;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: flex-start;
    }

    label {
      margin: 0;
      font-weight: 500;
      color: $bc !important;
      cursor: pointer;
      margin-right: 40px;
      position: relative !important;
      top: auto;

      @include media-breakpoint-down(xs) {
        top: -10px !important;
        font-size: 12px !important;
        line-height: 14px !important;
        color: #a5a5a5 !important;

      }
    }

    input {
      border: none;
      border-bottom: 1px solid $bc;
      outline: none;
      display: block;
      max-width: 100%;
      width: 100%;

      @include media-breakpoint-down(sm) {
        max-width: 350px;
        right: 0;
      }

      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }

      &:focus {
        border-color: $accent-color;
        color: $bc;
      }

      &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 0;
        background-color: $accent-color;
        left: 0;
        bottom: 0;
        transition: $transition;
      }

      &:hover,
      &:focus,
      &:active {
        &:before {
          width: 100%;
        }
      }
    }

  }

  .control {
    display: inline-block;
    margin-right: 59px;

    @include media-breakpoint-down(xs) {
      margin-right: 45px;
    }

    * {
      color: $accent-color;
      font-size: 18px;
      line-height: 21px;
      position: relative;
      margin-top: 5px;
      display: block;
      cursor: pointer;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}

.input-profile-wrap {
  position: relative;
  max-width: 400px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: flex-end;
    max-width: 350px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 100%;
    position: static !important;
    margin-bottom: 10px;
  }

  .has-error {
    color: red;
    position: absolute;
    top: -100%;
    left: 0;
    font-size: 14px;

    @include media-breakpoint-down(xs) {
      bottom: -10px;
      top: auto;
    }
  }

  .inputError {
    border-bottom: 1px solid red;
  }
}

.pay p.has-error {
  text-align: center;
}