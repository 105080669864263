.reservation {
  &__calendar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(lg) {
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      width: 100%;
      margin-bottom: 0;
    }
  }

  .dropdown-floor {
    display: inherit;
    z-index: 9;

    @include media-breakpoint-down(xs) {
      width: 100%;
      max-width: 100%;
      border-top: 1px solid $wc;
      color: $wc;
    }
  }

  &__reservation-date {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-right: 100px;
    min-width: 655px;

    @media (max-width: 1619.98px) {
      margin-right: 50px;
      min-width: 425px;
    }

    @media (max-width: 1299.98px) {
      margin-right: 30px;
      // Удалить, если появятся блоки или этажи //
      margin-right: 0;
      justify-content: center;
      // Удалить, если появятся блоки или этажи //
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
      margin-right: 0;
      justify-content: center;
    }

    @include media-breakpoint-down(xs) {
      min-width: 100%;
    }

    h2 {
      color: $accent-color;
      cursor: pointer;
      margin-right: 10px;

      @media (max-width: 1619.98px) {
        font-size: 20px;
      }

      @media (max-width: 1299.98px) {
        font-size: 18px;
      }

      @include media-breakpoint-down(md) {
        margin-right: 0;
      }

    }

    P {
      color: rgba(196, 196, 196, 0.5);

      @media (max-width: 1619.98px) {
        font-size: 15px;
      }

      @media (max-width: 1299.98px) {
        font-size: 14px;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__day-slide {
    margin-right: 18px;

    @media (max-width: 1619.98px) {
      margin-right: 5px;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }


    a {
      color: $accent-color;
      font-size: 23px;

      @media (max-width: 1619.98px) {
        font-size: 18px;
      }

      &:hover {
        color: $wc;
      }
    }
  }


  &__date-img {
    margin-right: 10px;
    max-width: 28px;
    width: 100%;

    @media (max-width: 1619.98px) {
      max-width: 22px;
    }

    @include media-breakpoint-down(md) {
      max-width: 20px;
    }

    img {
      width: 100%;
    }
  }

  &__panel-control {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .authorization {
    a {
      color: $wc;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin-left: 30px;
      transition: $transition;

      @media (max-width: 1619.98px) {
        font-size: 16px;
        margin-left: 20px;
      }

      &:hover {
        color: $accent-color;

        i {
          color: $accent-color;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }

    &__authorized {
      font-weight: 400 !important;
      color: #a5a5a5;
      cursor: pointer;
      position: relative;
      z-index: 10;

      .authorized-js {
        padding-right: 15px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          font-family: "icon";
          font-size: 10px !important;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      .dropdown-menu {
        position: absolute;
        right: 0;
        text-align: right;
        // min-width: 240px;
        // width: 100%;
        background-color: #1c1c1c;
        margin: 0;
        padding-right: 20px;
        padding-left: 20px;
        white-space: nowrap;
        display: none;
        border: 1px solid #CD8F38;
        border-top: none;
        top: 100%;
        margin-top: 10px;

        li {
          margin: 0;

          a {
            margin: 0;
            display: block;
            min-height: 51px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
      .dropdown-menu-open {
        display: block;
      }
    }
  }
}