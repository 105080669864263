.table-wrap {
  position: relative;

  &__table-header {
    border-bottom: 1px solid #3e3e3e;
    margin-bottom: 30px;
    display: none;

    @include media-breakpoint-down(md) {
      border-bottom: none;
      border-top: 1px solid $wc;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 40px;
      position: relative;
      cursor: pointer;
      flex-direction: column;

    }

    p {
      margin: 0;
    }
  }

  &__mobile-list-hall {
    display: none;
    position: relative;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &:after {
      content: '';
      position: absolute;
      font-family: 'icon';
      color: $wc;
      right: 15px;
      top: 19px;
      transform: translateY(-50%);
      font-size: 20px;
      transition: $transition;
    }
  }

  .open-list {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__header-item-wrap {
    width: 100%;
    display: flex;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__mobile-list-hall,
  &__header-item {
    width: 100%;
    text-align: center;
    padding-bottom: 14px;

    @media (max-width: 1619.98px) {
      padding-bottom: 10px;
    }

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid #ccc;
      padding-bottom: 0;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #5A5A5A;
      }
    }

    p {
      font-weight: 500;
      color: $wc;
      font-size: 14px;

      @media (max-width: 1619.98px) {
        font-size: 12.5px;
      }

      @include media-breakpoint-down(lg) {
        font-size: 11px;
      }

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }

      span {
        color: $accent-color;

        @media (max-width: 1619.98px) {
          font-size: 13px;
        }

        @include media-breakpoint-down(md) {
          font-size: 18px;
        }
      }
    }
  }

  .table {
    margin: 0;
    display: none;

    .td-free {
      padding: 6.9px 0 !important;
      background-color: #0E0E0E;
    }

    .tr-free {
      background-color: #0E0E0E;
    }


    .reserved {
      background-color: #C4C4C4;
      color: #fff;
      background-image: none !important;
      @include media-breakpoint-down(md) {
        background-color: #E5E5E5;

        &:hover {
          background-color: #E5E5E5;
        }
      }

      &:before {
        background-color: #868686;
      }

      &:hover {
        background-color: #C4C4C4;

        @include media-breakpoint-down(md) {
          &:hover {
            background-color: #E5E5E5;
          }
        }
      }

    }


    .reserved-by-me {
      background-color: #3e3e3e;
      color: #0bc142;

      &:before {
        background-color: #3e3e3e;
      }

      &:hover {
        background-color: $wc;
      }

      p {
        font-size: 13px;
        font-weight: 500;
        padding-left: 10px;

        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }
      }
    }

    .not-reserved-by-me {
      background: none !important;
      background-color: rgba(#C4C4C4, 0.5) !important;
      color: $wc;

      &:before {
        background-color: $wc;
      }

      &:hover {
        background-color: rgba(#C4C4C4, 0.5);

        .reservation-message {
          display: block;
          z-index: 9;
        }
      }

      p {
        font-size: 13px;
        font-weight: 500;
        padding-left: 10px;

        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }

        span {
          color: $accent-color;
        }
      }
    }

    .reservation-message {
      background: rgba(0, 0, 0, 0.8);
      text-align: left;
      padding: 20px 30px;
      min-width: 471px;
      position: absolute;
      left: 100%;
      margin-left: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: none;

      @include media-breakpoint-down(lg) {
        min-width: 350px;
        padding: 10px 20px;
      }

      @include media-breakpoint-down(md) {
        left: 50%;
        top: 110%;
        transform: translateX(-50%);
        min-width: 100%;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 10px;
        margin-top: 5px;
      }

      &:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-right: 20px solid rgba(0, 0, 0, 0.8);
        border-bottom: 15px solid transparent;
        right: 100%;
        top: 20px;

        @include media-breakpoint-down(md) {
          border-top: 10px solid transparent;
          border-right: 20px solid rgba(0, 0, 0, 0.8);
          border-bottom: 10px solid transparent;
          transform: rotate(90deg);
          left: 50%;
          bottom: 100%;
          top: auto;
        }
      }

      p {
        font-size: 16px;

        @include media-breakpoint-down(lg) {
          font-size: 14px;

          br {
            display: none;
          }
        }

        @include media-breakpoint-down(md) {
          line-height: normal;
          text-align: left;

          br {
            display: block;
          }
        }

        @include media-breakpoint-down(sm) {
          br {
            display: none;
          }
        }


      }
    }

    .added-to-basket {
      background-color: #c5c2c2b8;
      color: #fefefe;

      &:before {
        background-color: #fefefe;
      }

      &:hover {
        background-color: #c5c2c2b8;
      }

      p {
        font-size: 13px;
        font-weight: 500;
        padding: 0 5px 0 5px;
        color: #fefefe;

        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }

      }
    }


    .reserving {
      background-color: $wc;
      color: $accent-color;
      border: none !important;

      &:nth-child(2n) {
        border: none;
      }

      &:before {
        background-color: $accent-color;
      }

      &:hover {
        background-color: $wc;
      }
    }

    .reserving-time {
      color: $accent-color;

    }

    .time-half {
      opacity: 0;
    }

    .reservation-selection {
      min-width: 200px;
      position: absolute;
      left: 100%;
      top: 0;
      z-index: 1;
      padding: 10px;
      background-color: $wc;
      justify-content: center;
      border: 1px solid #CD8F38;
      border-radius: 10px;
      margin-left: 10px;

      @include media-breakpoint-down(md) {
        min-width: auto;
        max-width: 270px;
        width: 100%;
        margin: 0 auto;
        left: 50%;
        transform: translateX(-50%);
      }

      &:before {
        position: absolute;
        content: '';
        width: 25px;
        height: 28px;
        background-image: url('../assets/icons/arrow.svg');
        right: 100%;
        top: 12px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &:after {
        position: absolute;
        content: '';
        width: 2px;
        height: 22px;
        background-color: $wc;
        right: 100%;
        top: 15px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }


      .cancel {
        color: $accent-color;
        margin-top: 12px;
        position: relative;
        display: inline-block;
        font-size: 16px;
      }

      &--mobile-selection {
        top: 200%;
        right: auto;
        z-index: 999;
      }

      &:hover {
        .reservation-message {
          display: none !important;
        }
      }

    }

    .reservation-selection-position {
      left: 50% !important;
      top: 100% !important;
      transform: translateX(-50%) !important;
    }

    thead {
      @include media-breakpoint-down(md) {
        display: none;
      }

      tr {
        display: table-row;
        border: none;

        td {
          border: none;
          border-bottom: 1px solid #3e3e3e;
          padding: 0;

          &:first-child {
            border: none;
          }
        }
      }
    }

    tbody {

      &:before {
        content: "";
        display: block;
        height: 30px;
        color: transparent;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }


      tr {
        //display: none;
        transition: $transition;

        @include media-breakpoint-down(md) {
          display: table-row;
        }

        td {
          width: auto;
          border-right: 1px solid #3e3e3e;
          border-top: 1px solid #3e3e3e;
          padding: 7px 0 !important;
          color: $wc;
          font-size: 18px;
          font-weight: 500;
          cursor: pointer;
          transition: $transition;
          position: relative;
          text-align: center;
          vertical-align: middle;

          @media (max-width: 1299.98px) {
            font-size: 14px;
          }


          &:before {
            position: absolute;
            content: '';
            height: 100%;
            width: 10px;
            left: 0;
            top: 0;
          }

          &:first-child {
            cursor: default;
            width: 65px;
            border-top: none;

            &:before {
              display: none;
            }

            &:hover {
              background: none;
            }
          }

          &:hover {
            background-color: rgba(225, 225, 225, 0.15);

            @include media-breakpoint-down(md) {
              background: none;
            }
          }
        }

        .forbidden {
          cursor: default;
          // background-color: rgba(225, 225, 225, .15);
          background-image: url('../assets/img/reservation/forbidden-bg.png');
          background-size: cover;
          background-color: #270d0d;
          background-repeat: repeat-x;
          background-position: left;

          &:hover {
            background-image: url('../assets/img/reservation/forbidden-bg.png');
            background-size: cover;
            background-color: #270d0d;
            background-repeat: repeat-x;
            background-position: left;

            .reservation-message {
              display: block;
              z-index: 9;
            }
          }
        }


        &:last-child {
          td {
            border-bottom: 1px solid #3e3e3e;

            &:first-child {
              border-bottom: none;
            }
          }
        }

        &:nth-child(2n) {
          td {
            border-top: 1px dashed #3e3e3e;

            &:first-child {
              border-top: none;
            }
          }
        }
      }
    }
  }

  .scroll-table {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid $accent-color;
    border-radius: 0 0 10px 10px;
    transition: $transition;
    position: relative;
    margin-left: 65px;
    float: right;
    width: 95%;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &:hover {
      background-color: rgba(225, 225, 225, 0.15);
    }

    i {
      font-size: 25px;
      color: $accent-color;
    }

    &--top {
      border-radius: 10px 10px 0 0;
      display: none;
    }
  }


  .scroll-table-wrap {
    position: absolute;
    top: 37px;
    right: 0;
    width: 100%;
    max-width: 100%;
  }

  .desktop-table {
    .td-free {
      &:last-child {
        .reservation-selection {
          right: 100%;
          margin-right: 10px;
          left: auto;
          margin-left: 0;

          &:before {
            left: 100%;
            transform: rotate(180deg);
          }

          &:after {
            left: 100%;
          }
        }

        .reservation-message {
          left: auto;
          right: 100%;
          margin-left: 0;
          margin-right: 10px;

          &:before {
            left: 100%;
            transform: rotate(180deg);
          }
        }
      }
    }

    .prevTD {
      .reservation-selection {
        right: 100%;
        margin-right: 10px;
        left: auto;
        margin-left: 0;

        &:before {
          left: 100%;
          transform: rotate(180deg);
        }

        &:after {
          left: 100%;
        }
      }

      .reservation-message {
        right: 100%;
        left: auto;

        &:before {
          right: auto;
          left: 100%;
          transform: rotate(180deg);
        }
      }

    }
  }
}

.additional-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 65px;
  clear: both;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    margin-left: 0;
  }


  .hall-info {
    margin-top: 10px;
    color: $wc;
    max-width: 50%;
    width: 100%;

    @include media-breakpoint-down(md) {
      max-width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }

    p {
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
  }
}

.forbidden-past {
  cursor: pointer !important;
}

.identify {
  margin-top: 40px;
  margin-left: 65px;
  @include media-breakpoint-down(md) {
    margin-top: 30px;
    margin-left: 15px;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px;
  }

  p {
    color: $wc;
  }

  .byMe {
    background-color: #47CC5E;
  }

  .byOther {
    background-color: #5F5F5F;
  }

  .byOtherForMin {
    background-color: $wc;
  }

  span {
    max-width: 25px;
    width: 100%;
    height: 25px;
    border-radius: 2px;
    display: block;
    margin-right: 10px;
  }
}

