.popover {
  position: fixed;
  top: 50% !important;
  left: 50%!important;
  transform: translateY(-50%) translateX(-50%);
  z-index: 99999;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  background-color: $wc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  white-space: normal;
  margin-top: 5px;
  padding-bottom: 20px;

  .popover-content {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, .2);
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }

  .clockpicker-button {
    margin: 0 auto;
    margin-top: 20px;
  }

  .text-primary {
    color: $accent-color;
  }

  .clockpicker-tick {
    &:hover {
      background-color: #c0e5f7;
    }
  }
}
.timepicker {
  text-align: center;
}


