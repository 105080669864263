.calendar {


  .calendar-triple-js {
    display: none;
  }


  &__single {
    background-color: $wc;
    width: 100%;
    max-width: 515px;
    min-height: 700px;
    margin: auto;
    border-radius: 10px;
    padding: 40px 60px;
    text-align: center;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    @include media-breakpoint-down(md) {
      padding: 30px 10px;
      min-height: 525px;
      max-width: 400px;
    }
    @include media-breakpoint-down(sm) {
      padding: 40px 10px;
      max-width: 350px;
    }
    @include media-breakpoint-down(xs) {
      min-height: 400px;
    }

    .select-month {
      border: 1px solid #868686;
      height: 45px;
      border-radius: 5px;
      background: none;
      outline: none;
      padding: 0 30px 0 20px;
      margin-bottom: 20px;
      background-image: url('../assets/icons/arrow-down.svg');
      background-repeat: no-repeat;
      background-size: 10px 5px;
      background-position: 90%;
      appearance: none;
      cursor: pointer;
      margin-bottom: 20px;
      @include media-breakpoint-down(sm) {
        display: none;
      }

      option {
        cursor: pointer;
        border: 1px solid red;
      }
    }

    &--triple {
      max-width: 1385px;

      .calendar__date {
        &:nth-child(2) {
          margin: 0 29px;
        }
      }
    }
  }

  .inner-calendar {
    .window-popup__close {
      i {
        background-color: $accent-color;
      }
    }
  }

  &__month-caption {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  &__date {
    table {
      min-height: 395px;
      margin-bottom: 100px;
      @include media-breakpoint-down(md) {
        min-height: 300px;
        margin-top: 30px;
      }

      @include media-breakpoint-down(xs) {
        min-height: 250px;
        margin-top: 30px;
      }

      tr {
        height: 67px;
        @include media-breakpoint-down(md) {
          height: 30px;
        }
      }

      td {
        width: 45px;
        @include media-breakpoint-down(md) {
          width: 15px;
        }

        a {
          color: #5a5a5a;
          max-width: 45px;
          height: 45px;
          width: 100%;
          margin: auto;
          border-radius: 10px;
          background-color: transparent;
          font-weight: 400;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media-breakpoint-down(md) {
            height: 30px;
            width: 30px;
            border-radius: 5px;
          }
          @include media-breakpoint-down(xs) {
            font-size: 16px;
            line-height: 14px;
          }
        }

        .active,
        .hover-date {
          background-color: $accent-color;
          color: $wc;
        }
      }

      thead {
        margin-bottom: 40px;

        tr {
          td {
            width: 64px;
            color: #a5a5a5;
            @include media-breakpoint-down(md) {
              width: 40px;
            }
          }
        }
      }
    }
  }

  &__month-arrow {
    color: #c4c4c4;
    font-size: 20px;
    transition: $transition;

    &:hover {
      color: $accent-color;
    }

    @include media-breakpoint-down(xs) {
      font-size: 16px;
      color: $accent-color;
      &:hover {
        color: #c4c4c4;
      }
    }
  }

  &__date-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .btn-wrap {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.modal-opena {
  overflow: hidden;
  padding-right: 15px;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.weekend {
  background-color: transparent!important;
  color: red!important;
  pointer-events: none;
}