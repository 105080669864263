.inner-page {
  &--main-page {
    &--ipad {
      position: relative;

      .reservation-selection {
        transform: translateX(-50%);
        left: auto;
        right: 50%;
      }

      .reservation-selection {
        top: 200% !important;
        right: auto !important;
        z-index: 999 !important;
        min-width: auto !important;
        max-width: 270px !important;
        width: 100% !important;
        margin: 0 auto !important;
        left: 50% !important;

        &:before, &:after {
          display: none;
        }
      }

      .reservation-message {
        left: 50% !important;
        top: 110% !important;
        transform: translateX(-50%) !important;
        min-width: 100% !important;
        width: 100% !important;
        text-align: center !important;
        margin: 0 !important;
        padding: 10px !important;
        margin-top: 5px !important;

        p {
          font-size: 14px !important;
        }

        &:before {
          border-top: 10px solid transparent !important;
          border-right: 20px solid rgba(0, 0, 0, .8) !important;
          border-bottom: 10px solid transparent !important;
          transform: rotate(90deg) !important;
          left: 50% !important;
          bottom: 100% !important;
          top: auto !important;
        }
      }

      .reservation__header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        margin-bottom: 20px;
        position: relative;
        padding-top: 40px;
        @media (max-width: 575.98px) {
          padding-bottom: 20px;
        }

        .reservation__reservation-date {
          margin: 0;
          justify-content: center;
          position: relative;
        }

        .reservation__calendar {
          width: 100%;
        }

        .reservation__day-slide {
          position: absolute;
          margin-right: 0;
          display: flex;
          justify-content: space-around;
          width: 100%;
          @media (max-width: 767.98px) {
            justify-content: space-between;
          }
          @media (max-width: 575.98px) {
            display: none;
          }
        }

        .date-choose-js {
          position: relative;
          z-index: 999;
        }
      }

      .ipad-title {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 15px;
        color: $wc;
        font-size: 30px;
      }

      .dropdown__select {
        position: absolute;
        right: 0;
        top: 0;


        .lang {
          padding-top: 0;

          &:after {
            transform: translateY(-83%);
          }
        }
      }

      .reservation__panel-control {
        @media (max-width: 991.98px) {
          display: block;
        }
      }

      .current-day {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: rgba(196,196,196,.5);
      }
    }
  }


}


//@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
//
//}