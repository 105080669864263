@charset "UTF-8";
@import url(../assets/icons/icon/css/icon.css);
@import url(../js/libs/jquery-ui/jquery-ui.min.css);
@import url(../../node_modules/clockpicker/dist/bootstrap-clockpicker.css);
@import url(../../node_modules/slick-carousel/slick/slick.css);
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

body {
  font-family: "Ubuntu", sans-serif;
  background-color: #000; }

h1 {
  font-size: 32px;
  line-height: 37px;
  font-weight: 700; }
  @media (max-width: 1199.98px) {
    h1 {
      font-size: 25px;
      line-height: 30px; } }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 23px;
      line-height: 28px; } }
  @media (max-width: 767.98px) {
    h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 25px; } }
  @media (max-width: 575.98px) {
    h1 {
      font-size: 18px;
      line-height: 23px; } }

h2 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700; }
  @media (max-width: 575.98px) {
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px; } }

h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 25px; }
  @media (max-width: 575.98px) {
    h3 {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px; } }

h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px; }

h5 {
  font-size: 14px;
  text-align: left;
  display: block;
  width: 100%;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 0; }

input {
  border-radius: 0; }

.modal-description {
  color: #868686;
  text-align: center; }

.check-mail-window .btn {
  margin: 0 auto; }

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px; }
  @media (max-width: 767.98px) {
    p {
      font-size: 15px;
      line-height: 18px; } }
  @media (max-width: 575.98px) {
    p {
      font-size: 14px;
      line-height: 18px; } }

h1,
h2,
h3,
h4,
p {
  margin: 0; }

a {
  transition: all 0.3s; }
  a:hover {
    text-decoration: none; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style-type: none; }

.inner-page {
  background-color: #fff;
  padding-top: 13px;
  padding: 40px;
  padding-top: 70px;
  min-height: 100vh;
  position: relative; }
  @media (max-width: 991.98px) {
    .inner-page {
      padding: 20px 15px; } }
  .inner-page--main-page {
    background-color: #1c1c1c;
    height: 100%; }
    @media (max-width: 575.98px) {
      .inner-page--main-page {
        padding-left: 5px;
        padding-right: 5px; } }

.caption-wrap {
  border-bottom: 1px solid #CD8F38;
  padding-bottom: 19px;
  display: flex;
  justify-content: center; }
  .caption-wrap h1 {
    color: #000;
    width: 100%;
    text-align: center;
    padding-left: 80px; }
    @media (max-width: 575.98px) {
      .caption-wrap h1 {
        padding-left: 15px; } }
  .caption-wrap--press-left {
    justify-content: space-between;
    padding-left: 0; }
    .caption-wrap--press-left h1 {
      padding-left: 0;
      width: auto; }
    @media (max-width: 767.98px) {
      .caption-wrap--press-left {
        flex-direction: column;
        align-items: center; } }

.return-button {
  position: absolute;
  top: 75px;
  left: 40px; }
  @media (max-width: 991.98px) {
    .return-button {
      top: 23px;
      left: 15px; } }
  @media (max-width: 767.98px) {
    .return-button {
      top: 20px; } }
  @media (max-width: 575.98px) {
    .return-button {
      top: 32px; } }
  .return-button a {
    color: #CD8F38;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
    padding-left: 26px; }
    @media (max-width: 575.98px) {
      .return-button a {
        padding-left: 0; } }
    .return-button a:before {
      position: absolute;
      content: '';
      font-family: 'icon';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 10.5px;
      height: 21px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      color: #CD8F38; }
      @media (max-width: 991.98px) {
        .return-button a:before {
          font-size: 20px; } }
    @media (max-width: 575.98px) {
      .return-button a span {
        display: none; } }

.btn-wrap {
  display: inline-block; }
  .btn-wrap--windowPopup {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px; }
    .btn-wrap--windowPopup .btn {
      color: #ffffff !important; }
      .btn-wrap--windowPopup .btn:hover {
        color: #CD8F38 !important; }

.btn {
  background-color: #CD8F38;
  color: #fff;
  outline: none;
  white-space: nowrap;
  min-width: 174px;
  min-height: 45px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: inherit;
  border: 1px solid #CD8F38;
  transition: all 0.3s;
  padding: 0 25px; }
  .btn:hover {
    background-color: transparent;
    color: #CD8F38;
    box-shadow: 0 8px 16px 0 rgba(205, 143, 56, 0.24); }
  .btn--table-reservation {
    max-width: 180px;
    min-width: auto;
    margin: auto; }
    @media (max-width: 1619.98px) {
      .btn--table-reservation {
        font-size: 14px; } }
    @media (max-width: 1299.98px) {
      .btn--table-reservation {
        min-height: 35px;
        font-size: 12px; } }
    .btn--table-reservation:hover {
      box-shadow: none;
      background-color: #8a8484;
      border-color: #8a8484;
      color: #ffffff; }
  .btn--table-reservation-cancel {
    max-width: 180px;
    min-width: auto;
    margin: auto;
    margin-top: 10px;
    background-color: #DC2222;
    border-color: #DC2222; }
    .btn--table-reservation-cancel:hover {
      background-color: #8a8484;
      color: #ffffff;
      box-shadow: none;
      border-color: #8a8484; }
    @media (max-width: 1619.98px) {
      .btn--table-reservation-cancel {
        font-size: 14px; } }
    @media (max-width: 1299.98px) {
      .btn--table-reservation-cancel {
        min-height: 35px;
        font-size: 12px; } }
  .btn--additional-btn {
    margin-top: 5px !important; }

.return-btn {
  margin-right: 30px;
  color: #000;
  font-size: 18px;
  line-height: 21px;
  position: relative; }
  @media (max-width: 575.98px) {
    .return-btn {
      margin-right: 0; } }
  .return-btn:hover {
    color: #CD8F38; }
    .return-btn:hover:after {
      width: 0;
      background-color: #CD8F38; }
  .return-btn:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    height: 1px;
    background-color: #000;
    transition: all 0.3s; }

.pagination {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .pagination__item {
    display: inline-block;
    color: #868686;
    margin-right: 15px; }
    .pagination__item a {
      color: #868686; }
      .pagination__item a:hover {
        color: #CD8F38; }
    .pagination__item--active a {
      color: #CD8F38;
      cursor: default; }

.affiliation-user h2 {
  color: #868686;
  font-weight: 700;
  margin: 0; }
  @media (max-width: 767.98px) {
    .affiliation-user h2 {
      font-size: 16px;
      margin-top: 5px;
      line-height: 18px;
      font-weight: 400; } }

.booked {
  filter: grayscale(100%);
  opacity: .2;
  pointer-events: none; }

.agreement {
  display: flex;
  justify-content: center;
  position: relative; }
  @media (max-width: 767.98px) {
    .agreement {
      padding-left: 45px; } }
  .agreement input {
    margin-right: 27px;
    opacity: 0 !important;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    position: absolute !important; }
    .agreement input:checked + label:before {
      border: 1px solid #CD8F38; }
    .agreement input:checked + label:after {
      opacity: 1;
      transform: translateY(-70%) rotate(-40deg); }
  .agreement label {
    color: #868686;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    margin: 0;
    margin-right: 5px;
    position: relative;
    cursor: pointer; }
    .agreement label:before {
      position: absolute;
      content: '';
      width: 25px;
      height: 25px;
      left: -44px;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid #E5E5E5; }
    .agreement label:after {
      position: absolute;
      content: '';
      width: 15px;
      height: 8px;
      border-bottom: 2px solid #CD8F38;
      border-left: 2px solid #CD8F38;
      left: -39px;
      top: 50%;
      transform: rotate(45deg) scale(0.2);
      opacity: 0;
      transition: all 0.3s; }
    .agreement label a {
      color: #868686;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      text-decoration: underline; }
      .agreement label a:hover {
        color: #CD8F38;
        text-decoration: none; }
  .agreement--reservation-hall {
    display: block;
    margin-bottom: 30px; }
    @media (max-width: 1199.98px) {
      .agreement--reservation-hall {
        margin-bottom: 25px; } }
    @media (max-width: 991.98px) {
      .agreement--reservation-hall {
        margin-bottom: 20px; } }
    @media (max-width: 767.98px) {
      .agreement--reservation-hall {
        padding-left: 0; } }
    .agreement--reservation-hall input {
      position: absolute; }
    .agreement--reservation-hall label {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #000;
      padding-left: 36px;
      white-space: nowrap; }
      @media (max-width: 1199.98px) {
        .agreement--reservation-hall label {
          padding-left: 34px;
          font-size: 17px; } }
      @media (max-width: 991.98px) {
        .agreement--reservation-hall label {
          padding-left: 32px;
          font-size: 16px; } }
      @media (max-width: 767.98px) {
        .agreement--reservation-hall label {
          padding-left: 30px;
          font-size: 16px; } }
      @media (max-width: 575.98px) {
        .agreement--reservation-hall label {
          padding-left: 20px;
          font-size: 14px;
          font-weight: 400; } }
      .agreement--reservation-hall label span {
        color: #A5A5A5; }
      .agreement--reservation-hall label:before {
        left: 0; }
        @media (max-width: 767.98px) {
          .agreement--reservation-hall label:before {
            width: 20px;
            height: 20px; } }
        @media (max-width: 575.98px) {
          .agreement--reservation-hall label:before {
            width: 17px;
            height: 17px; } }
      .agreement--reservation-hall label:after {
        left: 5px; }
        @media (max-width: 767.98px) {
          .agreement--reservation-hall label:after {
            width: 11px;
            height: 5px; } }
        @media (max-width: 575.98px) {
          .agreement--reservation-hall label:after {
            width: 8px;
            height: 4px;
            border-width: 1px; } }
  .agreement--windowPopup label {
    color: #000; }
  .agreement--time-reserving {
    margin-bottom: 10px;
    display: block;
    padding-left: 36px;
    break-inside: avoid; }
    .agreement--time-reserving label {
      color: #000000; }
      .agreement--time-reserving label:before {
        left: -36px;
        border-color: #000000; }
      .agreement--time-reserving label:after {
        left: -30px; }
  .agreement--time-reserving.reserved {
    pointer-events: none; }
    .agreement--time-reserving.reserved label:before, .agreement--time-reserving.reserved label:after {
      border-color: #868686 !important; }
    .agreement--time-reserving.reserved label:before {
      border-color: rgba(134, 134, 134, 0.3) !important; }
  .agreement--status-byMe input:checked + label:before {
    border: 1px solid #37BE21; }
  .agreement--status-byMe input:checked + label:after {
    border-color: #37BE21; }
  .agreement--by-anotherCustomer input:checked + label:before {
    border: 1px solid #DC2222; }
  .agreement--by-anotherCustomer input:checked + label:after {
    border-color: #DC2222; }
  .agreement--by-anotherCustomer-minute input:checked + label:before {
    border: 1px solid #FFB800; }
  .agreement--by-anotherCustomer-minute input:checked + label:after {
    border-color: #FFB800; }
  .agreement--by-anotherCustomer-minute, .agreement--by-anotherCustomer, .agreement--status-byMe {
    pointer-events: auto; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield;
  text-align: center; }

.quantity-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 26px;
  justify-content: center; }
  @media (max-width: 991.98px) {
    .quantity-block {
      margin-right: 0; } }
  .quantity-block input {
    opacity: 1;
    position: relative;
    max-width: 50px; }
  .quantity-block__wrap {
    display: flex;
    align-items: center; }
    .quantity-block__wrap input {
      text-align: center; }
    .quantity-block__wrap .plus,
    .quantity-block__wrap .minus {
      position: absolute;
      width: 10px;
      height: 10px;
      cursor: pointer; }
      .quantity-block__wrap .plus:before, .quantity-block__wrap .plus:after,
      .quantity-block__wrap .minus:before,
      .quantity-block__wrap .minus:after {
        position: absolute;
        content: '';
        background-color: #CD8F38; }
    .quantity-block__wrap .plus {
      right: 0; }
      .quantity-block__wrap .plus:before, .quantity-block__wrap .plus:after {
        height: 1px;
        width: 10px;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%); }
      .quantity-block__wrap .plus:after {
        transform: translateY(-50%) translateX(-50%) rotate(90deg); }
      @media (max-width: 991.98px) {
        .quantity-block__wrap .plus {
          right: 10px; } }
    .quantity-block__wrap .minus {
      left: 0; }
      .quantity-block__wrap .minus:before {
        height: 1px;
        width: 10px;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%); }
      @media (max-width: 991.98px) {
        .quantity-block__wrap .minus {
          left: 10px; } }

.pay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px; }
  @media (max-width: 575.98px) {
    .pay {
      flex-direction: column; } }
  .pay--reservation {
    margin-bottom: 60px;
    margin-top: 0; }
  .pay .order-1 {
    margin-bottom: 20px; }

.effect-underline {
  position: relative;
  transition: all 0.3s; }
  .effect-underline:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #CD8F38;
    transition: all 0.3s;
    left: 0;
    bottom: 0; }
  .effect-underline:hover {
    color: #CD8F38; }
    .effect-underline:hover:before {
      width: 0;
      background-color: #000; }
  .effect-underline--white:before {
    background-color: #fff; }
  .effect-underline--white:hover:before {
    background: #CD8F38; }
  .effect-underline--back:before {
    width: 0; }
  .effect-underline--back:hover:before {
    width: 100%;
    background: #CD8F38; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.modal {
  padding: 0 !important; }

form .input-js .label {
  transition: all 0.3s;
  margin: 0;
  color: #868686;
  font-size: 18px;
  line-height: 22px;
  position: absolute;
  top: 0; }
  form .input-js .label.filled {
    font-size: 12px;
    line-height: 14px;
    transform: translateY(-12px); }
  form .input-js .label.focused {
    color: #000; }

.inside-nav .reservation__header {
  padding-bottom: 0; }

.inside-nav .authorization a {
  color: #CD8F38 !important; }
  .inside-nav .authorization a:hover {
    color: #000 !important; }

.inside-nav .dropdown-menu {
  background-color: #fff !important; }
  .inside-nav .dropdown-menu a:hover {
    color: #000000 !important; }

.inside-nav .dropdown__select,
.inside-nav .lang {
  color: #CD8F38;
  background-color: #fff !important; }
  .inside-nav .dropdown__select .select-item,
  .inside-nav .lang .select-item {
    border: 1px solid #CD8F38;
    border-top: none; }
    .inside-nav .dropdown__select .select-item li,
    .inside-nav .lang .select-item li {
      color: #CD8F38;
      background-color: #fff !important; }
      .inside-nav .dropdown__select .select-item li:hover,
      .inside-nav .lang .select-item li:hover {
        color: #000 !important; }

.buy-more {
  max-width: 940px;
  width: 100%;
  margin: auto;
  margin-top: 50px; }
  @media (max-width: 991.98px) {
    .buy-more h1 {
      text-align: center; } }
  @media (max-width: 459.98px) {
    .buy-more h1 {
      text-align: left; } }
  .buy-more .window-popup__more-img {
    margin-top: 20px; }
    @media (max-width: 459.98px) {
      .buy-more .window-popup__more-img {
        margin-top: 10px; } }
  @media (max-width: 991.98px) {
    .buy-more .window-popup__more {
      max-width: 400px;
      width: 100%;
      margin: auto;
      margin-bottom: 3px; } }
  .buy-more .window-popup__more p {
    font-size: 18px;
    font-weight: 400; }

.dropdown {
  font-weight: 500;
  z-index: 1; }
  .dropdown__select {
    margin: 0;
    position: relative;
    background-color: #1c1c1c;
    color: #fff;
    z-index: 9; }
    .dropdown__select .active {
      color: #CD8F38; }
    .dropdown__select i {
      margin-left: 0;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 9px;
      cursor: pointer;
      transition: all 0.3s; }
      .dropdown__select i:hover {
        cursor: pointer;
        color: #CD8F38; }
    .dropdown__select .lang {
      text-align: center;
      background: #1c1c1c;
      display: inline-block;
      margin: 0;
      cursor: pointer;
      margin-left: 34px;
      padding-right: 19px;
      padding-top: 9px;
      padding-bottom: 9px;
      transition: all 0.3s;
      position: relative; }
      @media (max-width: 1619.98px) {
        .dropdown__select .lang {
          margin-left: 20px; } }
      .dropdown__select .lang:after {
        content: '';
        position: absolute;
        font-family: "icon";
        font-size: 10px;
        top: 50%;
        right: 6px;
        transform: translateY(-50%); }
      .dropdown__select .lang:hover {
        color: #CD8F38; }
    .dropdown__select .select-item {
      text-align: center;
      cursor: pointer;
      display: none;
      margin: 0;
      position: absolute;
      right: 0;
      background-color: #1c1c1c; }
      .dropdown__select .select-item li {
        list-style: none;
        background: #1c1c1c;
        width: 100%;
        text-align: right;
        padding-right: 19px;
        padding-bottom: 5px;
        margin-left: 0;
        transition: all 0.3s; }
        .dropdown__select .select-item li:hover {
          cursor: pointer;
          color: #CD8F38; }
    .dropdown__select .toggle {
      display: block !important;
      position: absolute;
      left: 0; }
  .dropdown--floor {
    margin-right: 90px; }
    @media (max-width: 1619.98px) {
      .dropdown--floor {
        margin-right: 45px; } }
    @media (max-width: 1299.98px) {
      .dropdown--floor {
        margin-right: 25px; } }
    @media (max-width: 991.98px) {
      .dropdown--floor:last-child {
        margin-right: 0; } }
    @media (max-width: 575.98px) {
      .dropdown--floor:last-child {
        border-right: none; } }
    @media (max-width: 575.98px) {
      .dropdown--floor {
        max-width: 100%;
        width: 100%;
        border-right: 1px solid #fff;
        margin: 0; } }
    .dropdown--floor .lang {
      color: #CD8F38;
      padding: 0;
      white-space: nowrap;
      margin: 0; }
      @media (max-width: 575.98px) {
        .dropdown--floor .lang {
          color: #fff;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center; } }
      @media (max-width: 575.98px) {
        .dropdown--floor .lang:before {
          position: absolute;
          content: '';
          font-family: 'icon';
          border: 0;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          font-size: 20px;
          background-color: #1c1c1c; } }
      .dropdown--floor .lang:after {
        content: '';
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-10%);
        border: 4px solid transparent;
        border-top: 4px solid #CD8F38;
        transition: all 0.3s; }
        @media (max-width: 575.98px) {
          .dropdown--floor .lang:after {
            display: none; } }
      .dropdown--floor .lang:hover {
        color: #fff; }
        .dropdown--floor .lang:hover:after {
          border-top: 4px solid #fff; }
    .dropdown--floor .select-item {
      min-width: 107px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 9px; }
      @media (max-width: 575.98px) {
        .dropdown--floor .select-item {
          padding: 0; } }
      @media (max-width: 575.98px) {
        .dropdown--floor .select-item .active {
          background-color: #5A5A5A; } }
      .dropdown--floor .select-item .active a {
        color: #fff; }
        @media (max-width: 575.98px) {
          .dropdown--floor .select-item .active a {
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff; } }
      .dropdown--floor .select-item li {
        text-align: center;
        padding-right: 0; }
        @media (max-width: 575.98px) {
          .dropdown--floor .select-item li {
            padding: 0; } }
        .dropdown--floor .select-item li a {
          color: #CD8F38; }
          @media (max-width: 575.98px) {
            .dropdown--floor .select-item li a {
              color: #fff;
              height: 40px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center; } }
          .dropdown--floor .select-item li a:hover {
            color: #fff; }
    .dropdown--floor .dropdown__select {
      width: 100%;
      max-width: 100%; }

.side-bar {
  width: 20%;
  background-color: #1B1C1B;
  height: 100vh; }

.profile {
  padding-top: 60px; }
  .profile__info .input-js .label {
    margin: 0;
    color: #6c757d;
    font-weight: 400; }
    .profile__info .input-js .label.filled {
      font-size: 12px;
      line-height: 14px;
      transform: translateY(-12px); }
    .profile__info .input-js .label.focused {
      color: #000; }
  .profile__affiliation {
    max-width: 660px;
    width: 100%;
    padding-bottom: 14px; }
    .profile__affiliation--personal-information p {
      font-weight: 400; }
    .profile__affiliation--affiliation-edit {
      width: auto;
      padding-right: 40px; }
      @media (max-width: 767.98px) {
        .profile__affiliation--affiliation-edit {
          padding-right: 0;
          max-width: 40%;
          width: 100%; } }
  .profile__affiliation-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    max-width: 500px;
    width: 100%; }
    @media (max-width: 575.98px) {
      .profile__affiliation-item {
        flex-direction: column; } }
  .profile__affiliation-label {
    max-width: 100%;
    width: 100%; }
    .profile__affiliation-label p {
      font-weight: 500;
      text-align-last: left; }
      @media (max-width: 575.98px) {
        .profile__affiliation-label p {
          font-size: 12px;
          line-height: 14px;
          color: #a5a5a5;
          margin-bottom: 10px; } }
  .profile__affiliation-info {
    max-width: 100%;
    width: 100%; }
    .profile__affiliation-info p {
      font-weight: 400;
      text-align-last: left; }
  .profile__input {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 26px; }
    @media (max-width: 575.98px) {
      .profile__input {
        flex-direction: column;
        align-items: flex-start; } }
    .profile__input label {
      margin: 0;
      font-weight: 500;
      color: #000 !important;
      cursor: pointer;
      margin-right: 40px;
      position: relative !important;
      top: auto; }
      @media (max-width: 575.98px) {
        .profile__input label {
          top: -10px !important;
          font-size: 12px !important;
          line-height: 14px !important;
          color: #a5a5a5 !important; } }
    .profile__input input {
      border: none;
      border-bottom: 1px solid #000;
      outline: none;
      display: block;
      max-width: 100%;
      width: 100%; }
      @media (max-width: 767.98px) {
        .profile__input input {
          max-width: 350px;
          right: 0; } }
      @media (max-width: 575.98px) {
        .profile__input input {
          max-width: 100%; } }
      .profile__input input:focus {
        border-color: #CD8F38;
        color: #000; }
      .profile__input input:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 0;
        background-color: #CD8F38;
        left: 0;
        bottom: 0;
        transition: all 0.3s; }
      .profile__input input:hover:before, .profile__input input:focus:before, .profile__input input:active:before {
        width: 100%; }
  .profile .control {
    display: inline-block;
    margin-right: 59px; }
    @media (max-width: 575.98px) {
      .profile .control {
        margin-right: 45px; } }
    .profile .control * {
      color: #CD8F38;
      font-size: 18px;
      line-height: 21px;
      position: relative;
      margin-top: 5px;
      display: block;
      cursor: pointer;
      font-weight: 400;
      text-transform: capitalize; }

.input-profile-wrap {
  position: relative;
  max-width: 400px;
  width: 100%; }
  @media (max-width: 767.98px) {
    .input-profile-wrap {
      display: flex;
      justify-content: flex-end;
      max-width: 350px; } }
  @media (max-width: 575.98px) {
    .input-profile-wrap {
      max-width: 100%;
      position: static !important;
      margin-bottom: 10px; } }
  .input-profile-wrap .has-error {
    color: red;
    position: absolute;
    top: -100%;
    left: 0;
    font-size: 14px; }
    @media (max-width: 575.98px) {
      .input-profile-wrap .has-error {
        bottom: -10px;
        top: auto; } }
  .input-profile-wrap .inputError {
    border-bottom: 1px solid red; }

.pay p.has-error {
  text-align: center; }

.history .table, .purchases .table {
  margin-top: 17px;
  margin-bottom: 40px;
  border-bottom: 1px solid #868686; }
  .history .table thead th, .purchases .table thead th {
    border-top: none;
    border-bottom: 1px solid #CD8F38;
    font-weight: 500;
    font-size: 18px;
    padding: 20px; }
    @media (max-width: 1199.98px) {
      .history .table thead th, .purchases .table thead th {
        padding: 15px;
        font-size: 14px; } }
    @media (max-width: 991.98px) {
      .history .table thead th, .purchases .table thead th {
        padding: 10px;
        font-size: 13px; } }
    @media (max-width: 767.98px) {
      .history .table thead th, .purchases .table thead th {
        padding: 5px;
        font-size: 12px; } }
    @media (max-width: 575.98px) {
      .history .table thead th, .purchases .table thead th {
        font-size: 10px; } }
    .history .table thead th:last-child, .purchases .table thead th:last-child {
      text-align: right; }
      @media (max-width: 575.98px) {
        .history .table thead th:last-child, .purchases .table thead th:last-child {
          text-align: left; } }
  .history .table td, .purchases .table td {
    font-weight: 400;
    font-size: 18px;
    border-top: 1px solid #868686;
    padding: 20px;
    vertical-align: middle; }
    @media (max-width: 1199.98px) {
      .history .table td, .purchases .table td {
        padding: 15px;
        font-size: 16px; } }
    @media (max-width: 991.98px) {
      .history .table td, .purchases .table td {
        padding: 10px;
        font-size: 15px; } }
    @media (max-width: 767.98px) {
      .history .table td, .purchases .table td {
        padding: 5px;
        font-size: 14px; } }
    @media (max-width: 575.98px) {
      .history .table td, .purchases .table td {
        font-size: 8px; } }
    .history .table td:last-child, .purchases .table td:last-child {
      text-align: right; }
      @media (max-width: 575.98px) {
        .history .table td:last-child, .purchases .table td:last-child {
          text-align: left; } }

@media (max-width: 575.98px) {
  .purchases .table {
    display: none; } }

.purchases td:last-child {
  width: 40%; }
  @media (max-width: 1600.98px) {
    .purchases td:last-child {
      width: 30%; } }
  @media (max-width: 1199.98px) {
    .purchases td:last-child {
      padding-left: 0;
      width: auto; } }
  .purchases td:last-child .btn {
    float: right;
    padding: 0 40px; }
    @media (max-width: 991.98px) {
      .purchases td:last-child .btn {
        font-size: 13px;
        min-width: 200px;
        padding: 0 10px; } }
    @media (max-width: 767.98px) {
      .purchases td:last-child .btn {
        font-size: 11px;
        min-height: 35px;
        min-width: 150px;
        padding: 0 5px; } }
    @media (max-width: 575.98px) {
      .purchases td:last-child .btn {
        font-size: 8px;
        min-height: 30px;
        min-width: 0;
        padding: 0;
        white-space: normal;
        background-color: transparent;
        color: #CD8F38;
        border: none;
        text-align: center; } }

.purchases .tabs {
  margin-top: 55px;
  margin-bottom: 30px; }
  @media (max-width: 575.98px) {
    .purchases .tabs {
      border-bottom: 1px solid #CD8F38;
      padding-bottom: 10px;
      margin-bottom: 0;
      margin-top: 10px; } }
  .purchases .tabs__item {
    display: inline-block;
    margin-right: 30px; }
    .purchases .tabs__item a {
      font-weight: 700;
      font-size: 22px;
      line-height: 25px;
      color: #868686; }
      @media (max-width: 1199.98px) {
        .purchases .tabs__item a {
          font-size: 20px;
          line-height: 23px; } }
      @media (max-width: 991.98px) {
        .purchases .tabs__item a {
          font-size: 18px;
          line-height: 21px;
          font-weight: 500; } }
      @media (max-width: 767.98px) {
        .purchases .tabs__item a {
          font-size: 16px;
          line-height: 19px; } }
  .purchases .tabs .active a {
    color: #CD8F38; }

.irrevocable {
  pointer-events: none;
  background-color: rgba(205, 143, 56, 0.4);
  border: 1px solid rgba(205, 143, 56, 0.1);
  position: relative; }

.mobile-table-purchases {
  display: none;
  margin-bottom: 50px; }
  @media (max-width: 575.98px) {
    .mobile-table-purchases {
      display: block; } }
  .mobile-table-purchases .purchases-item {
    padding: 15px 0;
    border-bottom: 1px solid #CD8F38; }
    .mobile-table-purchases .purchases-item:last-child {
      border-bottom: none; }
    .mobile-table-purchases .purchases-item span {
      color: #a5a5a5;
      font-size: 14px;
      line-height: 16px; }
    .mobile-table-purchases .purchases-item h3 {
      font-weight: 700; }
  .mobile-table-purchases .purchases-date-time {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .mobile-table-purchases .purchases-control-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .mobile-table-purchases .purchases-control-panel h3 {
      color: #CD8F38; }
    .mobile-table-purchases .purchases-control-panel .btn {
      font-size: 16px;
      line-height: 18px;
      min-height: 35px;
      padding: 0 20px; }

.btnWrap {
  position: relative;
  display: inline-flex;
  align-items: center; }
  @media (max-width: 575.98px) {
    .btnWrap .btn {
      font-size: 13px !important; } }
  .btnWrap span {
    max-width: 20px;
    width: 100%;
    display: inline-block;
    margin-right: 10px; }
    @media (max-width: 767.98px) {
      .btnWrap span {
        max-width: 14px;
        right: 6px; } }
    @media (max-width: 575.98px) {
      .btnWrap span {
        right: 10px; } }
    .btnWrap span img {
      width: 100%; }
    .btnWrap span:hover {
      cursor: pointer; }
      .btnWrap span:hover .info-window {
        display: block; }
  .btnWrap .info-window {
    position: absolute;
    background: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    min-width: 100px;
    text-align: center;
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.3);
    bottom: 120%;
    left: 0;
    display: none; }
    .btnWrap .info-window p {
      margin-bottom: 4px;
      font-size: 14px;
      line-height: normal; }

.service {
  padding-top: 35px; }
  .service__selected {
    border-bottom: 1px solid #cd8f38;
    padding-bottom: 30px;
    margin-top: 40px;
    position: relative; }
    .service__selected:first-child {
      margin-top: 0 !important; }
  @media (max-width: 575.98px) {
    .service__selected-info {
      margin-bottom: 20px; } }
  .service__selected-info h2 {
    color: #000;
    margin-bottom: 20px; }
    @media (max-width: 767.98px) {
      .service__selected-info h2 {
        font-size: 20px; } }
  .service__selected-info .additionally {
    margin-top: 20px; }
    @media (max-width: 575.98px) {
      .service__selected-info .additionally {
        margin-top: 15px; } }
  .service__selected-date {
    display: flex;
    align-items: flex-start;
    max-width: 500px;
    width: 100%;
    margin-bottom: 30px; }
    @media (max-width: 991.98px) {
      .service__selected-date {
        max-width: 100%; } }
    @media (max-width: 575.98px) {
      .service__selected-date {
        margin-bottom: 10px; } }
    .service__selected-date span {
      font-size: 18px;
      line-height: 22px;
      color: #000;
      font-weight: 400; }
      @media (max-width: 767.98px) {
        .service__selected-date span {
          font-size: 15px; } }
  .service__sub-caption {
    margin: 0;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: #000; }
    @media (max-width: 767.98px) {
      .service__sub-caption {
        font-size: 18px; } }
    @media (max-width: 575.98px) {
      .service__sub-caption {
        font-size: 16px;
        font-weight: 400; } }
    .service__sub-caption--small {
      margin-top: 20px;
      font-size: 18px;
      line-height: 22px;
      color: #000;
      font-weight: 500; }
  .service__img {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: calc(100% - 30px);
    position: relative;
    padding-bottom: 30px; }
    @media (max-width: 575.98px) {
      .service__img {
        align-items: center;
        margin-top: 40px;
        padding-bottom: 0; } }
  .service__selected-total {
    text-align: right;
    color: #868686; }
    @media (max-width: 575.98px) {
      .service__selected-total {
        position: absolute;
        top: 0; } }
  .service__img-wrap {
    max-width: 480px;
    width: 100%; }
    @media (max-width: 575.98px) {
      .service__img-wrap {
        max-width: 100%; } }
    .service__img-wrap img {
      width: 100%;
      max-width: 100%; }
  .service__img-control {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
    width: 100%;
    max-width: 100%; }
    @media (max-width: 767.98px) {
      .service__img-control {
        display: flex;
        justify-content: space-between;
        max-width: 200px;
        align-items: center; } }
    @media (max-width: 575.98px) {
      .service__img-control {
        margin-top: 20px;
        max-width: 100%; } }
    .service__img-control a {
      cursor: pointer;
      color: #CD8F38;
      margin-left: 38px;
      margin-bottom: 0; }
      @media (max-width: 767.98px) {
        .service__img-control a {
          font-size: 13px;
          margin: 0; } }
      @media (max-width: 575.98px) {
        .service__img-control a {
          font-size: 16px;
          color: #000; } }
      .service__img-control a:first-child {
        margin-left: 0; }
        @media (max-width: 575.98px) {
          .service__img-control a:first-child {
            border: 1px solid #CD8F38;
            border-radius: 30px;
            padding: 10px 15px;
            color: #CD8F38; }
            .service__img-control a:first-child:hover {
              box-shadow: 0 8px 16px 0 rgba(205, 143, 56, 0.24); }
            .service__img-control a:first-child:before {
              display: none; } }
  .service__total-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 13px;
    width: 100%;
    margin-bottom: 20px; }
    .service__total-wrap p {
      font-size: 32px;
      line-height: 37px;
      margin: 0;
      font-weight: 700;
      text-align: right; }
      @media (max-width: 575.98px) {
        .service__total-wrap p {
          font-size: 26px;
          line-height: 30px; } }
    .service__total-wrap--reservation {
      border-bottom: 1px solid #CD8F38;
      padding-bottom: 11px;
      padding-top: 60px;
      margin-bottom: 0; }
  .service__total p {
    color: #CD8F38; }
  .service__total .change-currency {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1px; }
    .service__total .change-currency__item {
      display: inline-block;
      margin-right: 9px; }
      .service__total .change-currency__item:last-child {
        margin-right: 0; }
      .service__total .change-currency__item a {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #868686; }
        .service__total .change-currency__item a:hover {
          color: #CD8F38; }
      .service__total .change-currency__item--active a {
        color: #CD8F38; }
  .service__empty-list {
    text-align: center; }
    .service__empty-list h2 {
      margin-bottom: 10px; }
    .service__empty-list p {
      margin-bottom: 52px; }
    .service__empty-list a {
      color: #CD8F38; }
  .service .additional-information p {
    margin-top: 7px;
    text-align: center; }

.reservation__calendar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 1199.98px) {
    .reservation__calendar {
      margin-bottom: 10px; } }
  @media (max-width: 991.98px) {
    .reservation__calendar {
      flex-direction: column;
      width: 100%;
      margin-bottom: 0; } }

.reservation .dropdown-floor {
  display: inherit;
  z-index: 9; }
  @media (max-width: 575.98px) {
    .reservation .dropdown-floor {
      width: 100%;
      max-width: 100%;
      border-top: 1px solid #fff;
      color: #fff; } }

.reservation__reservation-date {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-right: 100px;
  min-width: 655px; }
  @media (max-width: 1619.98px) {
    .reservation__reservation-date {
      margin-right: 50px;
      min-width: 425px; } }
  @media (max-width: 1299.98px) {
    .reservation__reservation-date {
      margin-right: 30px;
      margin-right: 0;
      justify-content: center; } }
  @media (max-width: 991.98px) {
    .reservation__reservation-date {
      margin-bottom: 20px;
      margin-right: 0;
      justify-content: center; } }
  @media (max-width: 575.98px) {
    .reservation__reservation-date {
      min-width: 100%; } }
  .reservation__reservation-date h2 {
    color: #CD8F38;
    cursor: pointer;
    margin-right: 10px; }
    @media (max-width: 1619.98px) {
      .reservation__reservation-date h2 {
        font-size: 20px; } }
    @media (max-width: 1299.98px) {
      .reservation__reservation-date h2 {
        font-size: 18px; } }
    @media (max-width: 991.98px) {
      .reservation__reservation-date h2 {
        margin-right: 0; } }
  .reservation__reservation-date P {
    color: rgba(196, 196, 196, 0.5); }
    @media (max-width: 1619.98px) {
      .reservation__reservation-date P {
        font-size: 15px; } }
    @media (max-width: 1299.98px) {
      .reservation__reservation-date P {
        font-size: 14px; } }
    @media (max-width: 991.98px) {
      .reservation__reservation-date P {
        display: none; } }

.reservation__day-slide {
  margin-right: 18px; }
  @media (max-width: 1619.98px) {
    .reservation__day-slide {
      margin-right: 5px; } }
  @media (max-width: 991.98px) {
    .reservation__day-slide {
      display: none; } }
  .reservation__day-slide a {
    color: #CD8F38;
    font-size: 23px; }
    @media (max-width: 1619.98px) {
      .reservation__day-slide a {
        font-size: 18px; } }
    .reservation__day-slide a:hover {
      color: #fff; }

.reservation__date-img {
  margin-right: 10px;
  max-width: 28px;
  width: 100%; }
  @media (max-width: 1619.98px) {
    .reservation__date-img {
      max-width: 22px; } }
  @media (max-width: 991.98px) {
    .reservation__date-img {
      max-width: 20px; } }
  .reservation__date-img img {
    width: 100%; }

.reservation__panel-control {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row; }
  @media (max-width: 991.98px) {
    .reservation__panel-control {
      display: none; } }

.reservation .authorization a {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-left: 30px;
  transition: all 0.3s; }
  @media (max-width: 1619.98px) {
    .reservation .authorization a {
      font-size: 16px;
      margin-left: 20px; } }
  .reservation .authorization a:hover {
    color: #CD8F38; }
    .reservation .authorization a:hover i {
      color: #CD8F38; }
  .reservation .authorization a:first-child {
    margin-left: 0; }

.reservation .authorization__authorized {
  font-weight: 400 !important;
  color: #a5a5a5;
  cursor: pointer;
  position: relative;
  z-index: 10; }
  .reservation .authorization__authorized .authorized-js {
    padding-right: 15px;
    position: relative; }
    .reservation .authorization__authorized .authorized-js:after {
      content: '';
      position: absolute;
      font-family: "icon";
      font-size: 10px !important;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
  .reservation .authorization__authorized .dropdown-menu {
    position: absolute;
    right: 0;
    text-align: right;
    background-color: #1c1c1c;
    margin: 0;
    padding-right: 20px;
    padding-left: 20px;
    white-space: nowrap;
    display: none;
    border: 1px solid #CD8F38;
    border-top: none;
    top: 100%;
    margin-top: 10px; }
    .reservation .authorization__authorized .dropdown-menu li {
      margin: 0; }
      .reservation .authorization__authorized .dropdown-menu li a {
        margin: 0;
        display: block;
        min-height: 51px;
        display: flex;
        justify-content: flex-end;
        align-items: center; }
  .reservation .authorization__authorized .dropdown-menu-open {
    display: block; }

@media (max-width: 575.98px) {
  #reservation .modal-wrap,
  #unfixed-reservation .modal-wrap,
  #sleep-capsule .modal-wrap {
    padding: 0; } }

.modal-wrap {
  padding: 15px;
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: center; }

.window-popup {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
  max-width: 458px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  @media (max-width: 1199.98px) {
    .window-popup {
      padding: 50px; } }
  @media (max-width: 991.98px) {
    .window-popup {
      padding: 40px; } }
  @media (max-width: 767.98px) {
    .window-popup {
      padding: 30px; } }
  .window-popup__close {
    background-size: contain;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    right: -66px;
    top: 40px;
    cursor: pointer;
    transition: all 0.3s;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 1199.98px) {
      .window-popup__close {
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px; } }
    .window-popup__close i {
      max-width: 100%;
      width: 100%;
      height: 2px;
      background-color: #fff;
      display: block;
      position: absolute; }
      @media (max-width: 1199.98px) {
        .window-popup__close i {
          background-color: #CD8F38; } }
      .window-popup__close i:first-child {
        transform: rotate(45deg); }
      .window-popup__close i:last-child {
        transform: rotate(-45deg); }
    .window-popup__close:hover {
      transform: rotate(-90deg); }
      .window-popup__close:hover i {
        background-color: #CD8F38; }
  .window-popup__input {
    width: 100%;
    max-width: 100%;
    position: relative;
    margin-top: 35px; }
    .window-popup__input input {
      width: 100%;
      max-width: 100%; }
  .window-popup__password-recovery {
    color: #A5A5A5;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: block;
    text-align: right;
    width: 100%;
    margin-top: 10px;
    transition: all 0.3s; }
    .window-popup__password-recovery:hover {
      color: #CD8F38; }
  .window-popup form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%; }
    .window-popup form input,
    .window-popup form textarea {
      border: none;
      border-bottom: 1px solid #A5A5A5;
      outline: none;
      color: #868686;
      background-color: transparent;
      z-index: 1;
      position: relative; }
      .window-popup form input:focus,
      .window-popup form textarea:focus {
        border-color: #000;
        color: #000; }
  .window-popup .caption {
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: #000; }
    .window-popup .caption--centralized {
      text-align: center;
      margin-bottom: 22px; }
  .window-popup .btn {
    margin-top: 35px; }
  .window-popup__caption {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #CD8F38;
    position: relative;
    padding-right: 20px; }
    @media (max-width: 575.98px) {
      .window-popup__caption--main-caption-reservation {
        justify-content: center; } }
  .window-popup__conference-hall {
    width: 100%;
    margin-bottom: 47px;
    margin-top: 40px;
    column-count: 3;
    list-style-type: none; }
    @media (max-width: 1199.98px) {
      .window-popup__conference-hall {
        margin-bottom: 35px;
        margin-top: 35px; } }
    @media (max-width: 991.98px) {
      .window-popup__conference-hall {
        margin-bottom: 30px;
        margin-top: 30px; } }
    @media (max-width: 767.98px) {
      .window-popup__conference-hall {
        margin-bottom: 25px;
        margin-top: 25px; } }
  .window-popup__type-of-seating {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 45px; }
    @media (max-width: 1199.98px) {
      .window-popup__type-of-seating {
        margin-bottom: 35px;
        margin-top: 35px; } }
    @media (max-width: 991.98px) {
      .window-popup__type-of-seating {
        margin-bottom: 30px;
        margin-top: 30px; } }
    @media (max-width: 767.98px) {
      .window-popup__type-of-seating {
        margin-bottom: 25px;
        margin-top: 25px; } }
    @media (max-width: 575.98px) {
      .window-popup__type-of-seating {
        margin: 15px 0; } }
  .window-popup__type-of-seating-item {
    margin-bottom: 20px; }
    @media (max-width: 575.98px) {
      .window-popup__type-of-seating-item {
        padding: 0 5px; } }
    .window-popup__type-of-seating-item img {
      width: 100%;
      max-width: 100%; }
    .window-popup__type-of-seating-item label {
      cursor: pointer;
      position: relative;
      margin: 0; }
      .window-popup__type-of-seating-item label .track-pointer,
      .window-popup__type-of-seating-item label .type-of-seating {
        position: absolute;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #756866;
        text-align: center; }
        @media (max-width: 1199.98px) {
          .window-popup__type-of-seating-item label .track-pointer,
          .window-popup__type-of-seating-item label .type-of-seating {
            font-size: 12px; } }
        @media (max-width: 991.98px) {
          .window-popup__type-of-seating-item label .track-pointer,
          .window-popup__type-of-seating-item label .type-of-seating {
            font-size: 11px; } }
        @media (max-width: 767.98px) {
          .window-popup__type-of-seating-item label .track-pointer,
          .window-popup__type-of-seating-item label .type-of-seating {
            font-size: 11px; } }
        @media (max-width: 575.98px) {
          .window-popup__type-of-seating-item label .track-pointer,
          .window-popup__type-of-seating-item label .type-of-seating {
            font-size: 10px;
            text-transform: none; } }
      .window-popup__type-of-seating-item label .track-pointer {
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        max-width: 100%; }
        @media (max-width: 575.98px) {
          .window-popup__type-of-seating-item label .track-pointer {
            font-size: 10px;
            top: 0;
            text-transform: none; } }
      .window-popup__type-of-seating-item label .type-of-seating {
        transform: translateY(-50%) rotate(-90deg);
        background-color: #ffb800;
        display: inline-block;
        color: #000;
        padding: 2px 5px;
        line-height: normal;
        top: 50%;
        left: -11px;
        font-size: 11px;
        font-weight: 400; }
      .window-popup__type-of-seating-item label .img-type-of-seating {
        border-radius: 5px;
        border: 1px solid #868686;
        display: block;
        position: relative; }
    .window-popup__type-of-seating-item .type-of-seating-input {
      position: absolute;
      opacity: 0; }
      .window-popup__type-of-seating-item .type-of-seating-input:checked + label .img-type-of-seating {
        border: 1px solid #CD8F38;
        box-shadow: 0 0 2px 2px #CD8F38; }
      .window-popup__type-of-seating-item .type-of-seating-input:checked + label .type-of-seating-name {
        color: #CD8F38; }
    .window-popup__type-of-seating-item .type-of-seating-name {
      text-align: left;
      margin-top: 5px; }
      @media (max-width: 1199.98px) {
        .window-popup__type-of-seating-item .type-of-seating-name {
          font-size: 16px; } }
      @media (max-width: 991.98px) {
        .window-popup__type-of-seating-item .type-of-seating-name {
          font-size: 15px; } }
      @media (max-width: 767.98px) {
        .window-popup__type-of-seating-item .type-of-seating-name {
          font-size: 14px; } }
    .window-popup__type-of-seating-item .download-type {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #CD8F38;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      cursor: pointer; }
      .window-popup__type-of-seating-item .download-type__desc {
        position: absolute; }
        .window-popup__type-of-seating-item .download-type__desc img {
          width: auto;
          margin: auto; }
        .window-popup__type-of-seating-item .download-type__desc span {
          display: block;
          margin-top: 5px; }
          @media (max-width: 991.98px) {
            .window-popup__type-of-seating-item .download-type__desc span {
              font-size: 13px;
              line-height: normal; } }
  .window-popup__reservation-hours {
    position: relative; }
    .window-popup__reservation-hours input {
      max-width: 60px !important;
      width: 100%;
      border-bottom: 1px solid #A5A5A5;
      color: #a5a5a5;
      position: relative;
      text-align: center; }
  .window-popup__reservation-date-wrap {
    width: 100%;
    margin: 40px 0 50px; }
    @media (max-width: 575.98px) {
      .window-popup__reservation-date-wrap {
        margin: 40px 0 36px; } }
    .window-popup__reservation-date-wrap p {
      color: #000;
      display: inline-block;
      position: relative; }
  .window-popup__reservation-item-wrap {
    margin-bottom: 20px; }
  .window-popup__reservation-item {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    flex-basis: 0; }
    @media (max-width: 767.98px) {
      .window-popup__reservation-item {
        flex-direction: column;
        align-items: flex-start; } }
    @media (max-width: 575.98px) {
      .window-popup__reservation-item--unfixed {
        display: none; } }
    .window-popup__reservation-item--mobile {
      display: none; }
      @media (max-width: 575.98px) {
        .window-popup__reservation-item--mobile {
          display: flex; } }
  .window-popup .add-more {
    color: #CD8F38;
    margin-left: 60px; }
    @media (max-width: 991.98px) {
      .window-popup .add-more {
        margin-left: 10px; } }
    @media (max-width: 767.98px) {
      .window-popup .add-more {
        margin-left: 30px; } }
    .window-popup .add-more--unfixed {
      margin: 0;
      margin-top: 10px;
      display: inline-block; }
  .window-popup .date-format {
    color: #000;
    margin-right: 18px; }
    .window-popup .date-format:active, .window-popup .date-format:focus {
      color: #000; }
  .window-popup__reservation-date {
    line-height: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end; }
    .window-popup__reservation-date .window-popup__unfixed-reservation {
      margin-right: 54px; }
      @media (max-width: 767.98px) {
        .window-popup__reservation-date .window-popup__unfixed-reservation {
          margin-right: 20px; } }
      @media (max-width: 575.98px) {
        .window-popup__reservation-date .window-popup__unfixed-reservation {
          display: none; } }
    .window-popup__reservation-date p {
      margin-top: 0;
      margin-right: 10px; }
      .window-popup__reservation-date p img {
        width: 20px;
        position: absolute;
        left: -30px;
        bottom: 0; }
  .window-popup__date-img {
    max-width: 20px;
    margin-right: 10px;
    display: inline-block; }
    .window-popup__date-img img,
    .window-popup__date-img svg {
      width: 100%;
      max-width: 100%; }
  .window-popup__date {
    border-bottom: 1px solid #000;
    cursor: pointer; }
  .window-popup__reservation-date-choose {
    margin-left: 30px;
    position: relative;
    font-size: 16px;
    line-height: 18px;
    color: #868686;
    margin-top: 22px;
    margin-bottom: 0; }
    .window-popup__reservation-date-choose:hover {
      color: #CD8F38; }
      .window-popup__reservation-date-choose:hover:after {
        width: 0;
        background-color: #CD8F38; }
    .window-popup__reservation-date-choose:after {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background-color: #000;
      left: 0;
      bottom: 0;
      transition: all 0.3s; }
  .window-popup__reservation-hours {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-end; }
    @media (max-width: 767.98px) {
      .window-popup__reservation-hours {
        margin: 25px 0 25px 20px; } }
    .window-popup__reservation-hours p {
      margin: 0 10px; }
  .window-popup__contact-details {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px; }
    .window-popup__contact-details .window-popup__input {
      max-width: 420px;
      display: inline-block;
      margin-right: 75px; }
      @media (max-width: 1199.98px) {
        .window-popup__contact-details .window-popup__input {
          margin-right: 60px; } }
      @media (max-width: 991.98px) {
        .window-popup__contact-details .window-popup__input {
          margin-right: 60px; } }
      @media (max-width: 767.98px) {
        .window-popup__contact-details .window-popup__input {
          max-width: 100%; } }
      .window-popup__contact-details .window-popup__input:nth-child(2n) {
        margin-right: 0; }
  .window-popup__additionally {
    display: none;
    width: 100%; }
  .window-popup__additionally-list {
    margin-top: 40px; }
  .window-popup__additionally-item {
    margin-bottom: 40px; }
  .window-popup__additionally-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000; }
    .window-popup__additionally-product .agreement {
      margin: 0; }
    @media (max-width: 575.98px) {
      .window-popup__additionally-product--address {
        flex-direction: column;
        align-items: flex-start; } }
    .window-popup__additionally-product--address label {
      margin: 0; }
    .window-popup__additionally-product--address textarea {
      border: none;
      resize: none;
      border-bottom: 1px solid #a5a5a5;
      height: 35px;
      outline: none;
      margin-left: 20px;
      width: 100%;
      max-width: 50%;
      font-size: 14px;
      padding: 5px;
      vertical-align: middle;
      display: flex; }
      @media (max-width: 575.98px) {
        .window-popup__additionally-product--address textarea {
          margin: 0;
          max-width: 100%;
          padding: 0;
          margin-top: 10px; } }
  .window-popup__additionally-product-total {
    display: flex;
    justify-content: space-between; }
  .window-popup__more {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
    .window-popup__more:hover .window-popup__more-img:before {
      opacity: 1; }
    .window-popup__more p {
      font-weight: 700;
      font-size: 22px;
      line-height: 25px;
      color: #000;
      margin-top: 14px;
      margin-bottom: 0; }
  .window-popup__more-img {
    width: 100%;
    margin-top: 40px;
    display: block;
    position: relative; }
    @media (max-width: 575.98px) {
      .window-popup__more-img {
        margin-top: 30px; } }
    .window-popup__more-img:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s; }
    .window-popup__more-img img {
      width: 100%; }
  .window-popup--reservation {
    max-width: 1000px;
    padding: 60px 40px; }
    @media (max-width: 1199.98px) {
      .window-popup--reservation {
        padding: 40px; } }
    @media (max-width: 991.98px) {
      .window-popup--reservation {
        padding: 30px; } }
    @media (max-width: 767.98px) {
      .window-popup--reservation {
        padding: 15px 15px 45px; } }
    @media (max-width: 575.98px) {
      .window-popup--reservation {
        border-radius: 0; } }
  .window-popup__description {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px; }
    @media (max-width: 767.98px) {
      .window-popup__description {
        margin-bottom: 30px; } }
    .window-popup__description ul {
      column-count: 2;
      margin-top: 15px; }
      @media (max-width: 575.98px) {
        .window-popup__description ul {
          column-count: 1; } }
      .window-popup__description ul li {
        position: relative;
        list-style-type: disc;
        margin-left: 20px;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 22px; }
        @media (max-width: 1199.98px) {
          .window-popup__description ul li {
            font-size: 17px;
            line-height: 21px; } }
        @media (max-width: 991.98px) {
          .window-popup__description ul li {
            font-size: 16px;
            line-height: 20px; } }
        @media (max-width: 767.98px) {
          .window-popup__description ul li {
            font-size: 15px;
            line-height: 19px; } }
  .window-popup__slider-wrap {
    position: relative;
    width: 100%;
    text-align: center; }
    @media (max-width: 575.98px) {
      .window-popup__slider-wrap {
        padding-bottom: 20px; } }
    .window-popup__slider-wrap .slick-list {
      width: 100%;
      padding-top: 5px; }
    .window-popup__slider-wrap .slider-arrows {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      flex-direction: row;
      display: none; }
      @media (max-width: 575.98px) {
        .window-popup__slider-wrap .slider-arrows {
          display: flex; } }
      .window-popup__slider-wrap .slider-arrows i {
        font-size: 20px;
        transition: .3s; }
      .window-popup__slider-wrap .slider-arrows .arrow-left {
        transform: translateX(-100%); }
      .window-popup__slider-wrap .slider-arrows .arrow-right {
        transform: translateX(100%); }
      .window-popup__slider-wrap .slider-arrows .arrow-left, .window-popup__slider-wrap .slider-arrows .arrow-right {
        cursor: pointer; }
        .window-popup__slider-wrap .slider-arrows .arrow-left:hover, .window-popup__slider-wrap .slider-arrows .arrow-right:hover {
          color: #CD8F38; }
  .window-popup__time-reserving {
    column-count: 6;
    margin-top: 20px; }
    @media (max-width: 991.98px) {
      .window-popup__time-reserving {
        column-count: 5; } }
    @media (max-width: 767.98px) {
      .window-popup__time-reserving {
        column-count: 4; } }
    @media (max-width: 575.98px) {
      .window-popup__time-reserving {
        column-count: 3; } }
    .window-popup__time-reserving label {
      white-space: nowrap; }
      .window-popup__time-reserving label:before {
        top: 53%;
        width: 22px;
        height: 22px; }
      .window-popup__time-reserving label:after {
        left: -32px; }
    .window-popup__time-reserving .inaccessible {
      pointer-events: none; }
      .window-popup__time-reserving .inaccessible label {
        color: rgba(0, 0, 0, 0.2); }
        .window-popup__time-reserving .inaccessible label:before {
          border-color: rgba(0, 0, 0, 0.2); }
  .window-popup__time-reserving-status {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column; }
    .window-popup__time-reserving-status .agreement label:before {
      top: 53%;
      width: 22px;
      height: 22px; }
    .window-popup__time-reserving-status .agreement label:after {
      left: -32px; }
  .window-popup--capsule {
    max-width: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    position: relative; }
    .window-popup--capsule form {
      align-items: flex-start; }
    .window-popup--capsule .capsule-container {
      max-width: 1000px;
      width: 100%; }
      @media (max-width: 767.98px) {
        .window-popup--capsule .capsule-container {
          width: 100%;
          max-width: 100%; } }
    .window-popup--capsule .service__total-wrap {
      position: relative; }
      .window-popup--capsule .service__total-wrap:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        bottom: 40px;
        background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 10px 1px;
        background-repeat: repeat-x; }
    .window-popup--capsule .service__total-cap,
    .window-popup--capsule .service__total {
      position: relative;
      background-color: #ffffff; }
    .window-popup--capsule .pay {
      width: 100%;
      display: flex;
      align-items: center; }
      .window-popup--capsule .pay .btn {
        margin-top: 0; }
    @media (max-width: 767.98px) {
      .window-popup--capsule .window-popup__contact-details .window-popup__input {
        margin-right: 0 !important; } }

.authorization-window {
  max-height: 376px; }

.trigger-js {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 0;
  cursor: pointer;
  color: #CD8F38;
  font-size: 27px;
  display: block;
  transition: all 0.3s; }

.rotate-arrow {
  transform: rotate(180deg); }

.close-btn-triple {
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px; }
  .close-btn-triple i {
    background-color: #CD8F38; }

.notice-wrap {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 575.98px) {
    .notice-wrap {
      align-items: flex-start;
      padding-top: 50px; } }
  .notice-wrap .notice-window {
    padding: 20px;
    max-width: 515px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 575.98px) {
      .notice-wrap .notice-window {
        max-width: 400px;
        padding: 15px; } }
    .notice-wrap .notice-window h3 {
      text-align: center;
      margin-bottom: 10px; }
    @media (max-width: 575.98px) {
      .notice-wrap .notice-window br {
        display: none; } }
    @media (max-width: 575.98px) {
      .notice-wrap .notice-window .btn {
        font-size: 13px; } }
    .notice-wrap .notice-window .delete-btn {
      margin-top: 10px;
      color: #CD8F38;
      display: block; }

.capsule-timeWrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 991.98px) {
    .capsule-timeWrap {
      flex-direction: column; } }
  .capsule-timeWrap .capsule-img {
    max-width: 480px;
    width: 100%;
    margin: 40px 0 50px; }
    @media (max-width: 991.98px) {
      .capsule-timeWrap .capsule-img {
        margin: 0 0 30px; } }
    .capsule-timeWrap .capsule-img img {
      width: 100%; }

.reservation__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-left: 60px;
  align-items: center; }
  @media (max-width: 991.98px) {
    .reservation__header {
      flex-direction: column;
      margin-left: 0; } }
  @media (max-width: 575.98px) {
    .reservation__header {
      padding-bottom: 0;
      padding-top: 20px; } }

.mobile-panel-control {
  display: none;
  border-top: 1px solid #CD8F38;
  border-bottom: 1px solid #CD8F38;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: center;
  padding: 0; }
  @media (max-width: 991.98px) {
    .mobile-panel-control {
      display: flex; } }
  .mobile-panel-control .authorization__authorized {
    border-right: 1px solid #CD8F38;
    width: 100%; }
    .mobile-panel-control .authorization__authorized a {
      color: #CD8F38;
      width: 100%;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .mobile-panel-control .authorization__authorized .dropdown {
      width: 100%; }
    .mobile-panel-control .authorization__authorized .dropdown-menu {
      width: 100%;
      padding-right: 10px;
      margin-top: 1px; }
    .mobile-panel-control .authorization__authorized .authorized-js {
      padding-right: 35px; }
      .mobile-panel-control .authorization__authorized .authorized-js:after {
        font-size: 20px !important;
        right: 10px; }
  .mobile-panel-control .dropdown__select .lang {
    color: #CD8F38;
    padding-right: 30px;
    margin-left: 15px; }
    .mobile-panel-control .dropdown__select .lang:after {
      font-size: 20px !important; }
  .mobile-panel-control .dropdown__select .select-item {
    border: 1px solid #CD8F38;
    border-top: none; }
    .mobile-panel-control .dropdown__select .select-item li {
      text-align: left;
      margin-left: 15px;
      padding-bottom: 1px; }

.table-wrap {
  position: relative; }
  .table-wrap__table-header {
    border-bottom: 1px solid #3e3e3e;
    margin-bottom: 30px;
    display: none; }
    @media (max-width: 991.98px) {
      .table-wrap__table-header {
        border-bottom: none;
        border-top: 1px solid #fff;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        position: relative;
        cursor: pointer;
        flex-direction: column; } }
    .table-wrap__table-header p {
      margin: 0; }
  .table-wrap__mobile-list-hall {
    display: none;
    position: relative; }
    @media (max-width: 991.98px) {
      .table-wrap__mobile-list-hall {
        display: block; } }
    .table-wrap__mobile-list-hall:after {
      content: '';
      position: absolute;
      font-family: 'icon';
      color: #fff;
      right: 15px;
      top: 19px;
      transform: translateY(-50%);
      font-size: 20px;
      transition: all 0.3s; }
  .table-wrap .open-list:after {
    transform: translateY(-50%) rotate(180deg); }
  .table-wrap__header-item-wrap {
    width: 100%;
    display: flex; }
    @media (max-width: 991.98px) {
      .table-wrap__header-item-wrap {
        display: none; } }
  .table-wrap__mobile-list-hall, .table-wrap__header-item {
    width: 100%;
    text-align: center;
    padding-bottom: 14px; }
    @media (max-width: 1619.98px) {
      .table-wrap__mobile-list-hall, .table-wrap__header-item {
        padding-bottom: 10px; } }
    @media (max-width: 991.98px) {
      .table-wrap__mobile-list-hall, .table-wrap__header-item {
        border-bottom: 1px solid #ccc;
        padding-bottom: 0;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .table-wrap__mobile-list-hall:hover, .table-wrap__header-item:hover {
          background-color: #5A5A5A; } }
    .table-wrap__mobile-list-hall p, .table-wrap__header-item p {
      font-weight: 500;
      color: #fff;
      font-size: 14px; }
      @media (max-width: 1619.98px) {
        .table-wrap__mobile-list-hall p, .table-wrap__header-item p {
          font-size: 12.5px; } }
      @media (max-width: 1199.98px) {
        .table-wrap__mobile-list-hall p, .table-wrap__header-item p {
          font-size: 11px; } }
      @media (max-width: 991.98px) {
        .table-wrap__mobile-list-hall p, .table-wrap__header-item p {
          font-size: 18px; } }
      .table-wrap__mobile-list-hall p span, .table-wrap__header-item p span {
        color: #CD8F38; }
        @media (max-width: 1619.98px) {
          .table-wrap__mobile-list-hall p span, .table-wrap__header-item p span {
            font-size: 13px; } }
        @media (max-width: 991.98px) {
          .table-wrap__mobile-list-hall p span, .table-wrap__header-item p span {
            font-size: 18px; } }
  .table-wrap .table {
    margin: 0;
    display: none; }
    .table-wrap .table .td-free {
      padding: 6.9px 0 !important;
      background-color: #0E0E0E; }
    .table-wrap .table .tr-free {
      background-color: #0E0E0E; }
    .table-wrap .table .reserved {
      background-color: #C4C4C4;
      color: #fff;
      background-image: none !important; }
      @media (max-width: 991.98px) {
        .table-wrap .table .reserved {
          background-color: #E5E5E5; }
          .table-wrap .table .reserved:hover {
            background-color: #E5E5E5; } }
      .table-wrap .table .reserved:before {
        background-color: #868686; }
      .table-wrap .table .reserved:hover {
        background-color: #C4C4C4; }
        @media (max-width: 991.98px) {
          .table-wrap .table .reserved:hover:hover {
            background-color: #E5E5E5; } }
    .table-wrap .table .reserved-by-me {
      background-color: #3e3e3e;
      color: #0bc142; }
      .table-wrap .table .reserved-by-me:before {
        background-color: #3e3e3e; }
      .table-wrap .table .reserved-by-me:hover {
        background-color: #fff; }
      .table-wrap .table .reserved-by-me p {
        font-size: 13px;
        font-weight: 500;
        padding-left: 10px; }
        @media (max-width: 1199.98px) {
          .table-wrap .table .reserved-by-me p {
            font-size: 12px; } }
    .table-wrap .table .not-reserved-by-me {
      background: none !important;
      background-color: rgba(196, 196, 196, 0.5) !important;
      color: #fff; }
      .table-wrap .table .not-reserved-by-me:before {
        background-color: #fff; }
      .table-wrap .table .not-reserved-by-me:hover {
        background-color: rgba(196, 196, 196, 0.5); }
        .table-wrap .table .not-reserved-by-me:hover .reservation-message {
          display: block;
          z-index: 9; }
      .table-wrap .table .not-reserved-by-me p {
        font-size: 13px;
        font-weight: 500;
        padding-left: 10px; }
        @media (max-width: 1199.98px) {
          .table-wrap .table .not-reserved-by-me p {
            font-size: 12px; } }
        .table-wrap .table .not-reserved-by-me p span {
          color: #CD8F38; }
    .table-wrap .table .reservation-message {
      background: rgba(0, 0, 0, 0.8);
      text-align: left;
      padding: 20px 30px;
      min-width: 471px;
      position: absolute;
      left: 100%;
      margin-left: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: none; }
      @media (max-width: 1199.98px) {
        .table-wrap .table .reservation-message {
          min-width: 350px;
          padding: 10px 20px; } }
      @media (max-width: 991.98px) {
        .table-wrap .table .reservation-message {
          left: 50%;
          top: 110%;
          transform: translateX(-50%);
          min-width: 100%;
          width: 100%;
          text-align: center;
          margin: 0;
          padding: 10px;
          margin-top: 5px; } }
      .table-wrap .table .reservation-message:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-right: 20px solid rgba(0, 0, 0, 0.8);
        border-bottom: 15px solid transparent;
        right: 100%;
        top: 20px; }
        @media (max-width: 991.98px) {
          .table-wrap .table .reservation-message:before {
            border-top: 10px solid transparent;
            border-right: 20px solid rgba(0, 0, 0, 0.8);
            border-bottom: 10px solid transparent;
            transform: rotate(90deg);
            left: 50%;
            bottom: 100%;
            top: auto; } }
      .table-wrap .table .reservation-message p {
        font-size: 16px; }
        @media (max-width: 1199.98px) {
          .table-wrap .table .reservation-message p {
            font-size: 14px; }
            .table-wrap .table .reservation-message p br {
              display: none; } }
        @media (max-width: 991.98px) {
          .table-wrap .table .reservation-message p {
            line-height: normal;
            text-align: left; }
            .table-wrap .table .reservation-message p br {
              display: block; } }
        @media (max-width: 767.98px) {
          .table-wrap .table .reservation-message p br {
            display: none; } }
    .table-wrap .table .added-to-basket {
      background-color: #c5c2c2b8;
      color: #fefefe; }
      .table-wrap .table .added-to-basket:before {
        background-color: #fefefe; }
      .table-wrap .table .added-to-basket:hover {
        background-color: #c5c2c2b8; }
      .table-wrap .table .added-to-basket p {
        font-size: 13px;
        font-weight: 500;
        padding: 0 5px 0 5px;
        color: #fefefe; }
        @media (max-width: 1199.98px) {
          .table-wrap .table .added-to-basket p {
            font-size: 12px; } }
    .table-wrap .table .reserving {
      background-color: #fff;
      color: #CD8F38;
      border: none !important; }
      .table-wrap .table .reserving:nth-child(2n) {
        border: none; }
      .table-wrap .table .reserving:before {
        background-color: #CD8F38; }
      .table-wrap .table .reserving:hover {
        background-color: #fff; }
    .table-wrap .table .reserving-time {
      color: #CD8F38; }
    .table-wrap .table .time-half {
      opacity: 0; }
    .table-wrap .table .reservation-selection {
      min-width: 200px;
      position: absolute;
      left: 100%;
      top: 0;
      z-index: 1;
      padding: 10px;
      background-color: #fff;
      justify-content: center;
      border: 1px solid #CD8F38;
      border-radius: 10px;
      margin-left: 10px; }
      @media (max-width: 991.98px) {
        .table-wrap .table .reservation-selection {
          min-width: auto;
          max-width: 270px;
          width: 100%;
          margin: 0 auto;
          left: 50%;
          transform: translateX(-50%); } }
      .table-wrap .table .reservation-selection:before {
        position: absolute;
        content: '';
        width: 25px;
        height: 28px;
        background-image: url("../assets/icons/arrow.svg");
        right: 100%;
        top: 12px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right; }
        @media (max-width: 991.98px) {
          .table-wrap .table .reservation-selection:before {
            display: none; } }
      .table-wrap .table .reservation-selection:after {
        position: absolute;
        content: '';
        width: 2px;
        height: 22px;
        background-color: #fff;
        right: 100%;
        top: 15px; }
        @media (max-width: 991.98px) {
          .table-wrap .table .reservation-selection:after {
            display: none; } }
      .table-wrap .table .reservation-selection .cancel {
        color: #CD8F38;
        margin-top: 12px;
        position: relative;
        display: inline-block;
        font-size: 16px; }
      .table-wrap .table .reservation-selection--mobile-selection {
        top: 200%;
        right: auto;
        z-index: 999; }
      .table-wrap .table .reservation-selection:hover .reservation-message {
        display: none !important; }
    .table-wrap .table .reservation-selection-position {
      left: 50% !important;
      top: 100% !important;
      transform: translateX(-50%) !important; }
    @media (max-width: 991.98px) {
      .table-wrap .table thead {
        display: none; } }
    .table-wrap .table thead tr {
      display: table-row;
      border: none; }
      .table-wrap .table thead tr td {
        border: none;
        border-bottom: 1px solid #3e3e3e;
        padding: 0; }
        .table-wrap .table thead tr td:first-child {
          border: none; }
    .table-wrap .table tbody:before {
      content: "";
      display: block;
      height: 30px;
      color: transparent; }
      @media (max-width: 991.98px) {
        .table-wrap .table tbody:before {
          display: none; } }
    .table-wrap .table tbody tr {
      transition: all 0.3s; }
      @media (max-width: 991.98px) {
        .table-wrap .table tbody tr {
          display: table-row; } }
      .table-wrap .table tbody tr td {
        width: auto;
        border-right: 1px solid #3e3e3e;
        border-top: 1px solid #3e3e3e;
        padding: 7px 0 !important;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s;
        position: relative;
        text-align: center;
        vertical-align: middle; }
        @media (max-width: 1299.98px) {
          .table-wrap .table tbody tr td {
            font-size: 14px; } }
        .table-wrap .table tbody tr td:before {
          position: absolute;
          content: '';
          height: 100%;
          width: 10px;
          left: 0;
          top: 0; }
        .table-wrap .table tbody tr td:first-child {
          cursor: default;
          width: 65px;
          border-top: none; }
          .table-wrap .table tbody tr td:first-child:before {
            display: none; }
          .table-wrap .table tbody tr td:first-child:hover {
            background: none; }
        .table-wrap .table tbody tr td:hover {
          background-color: rgba(225, 225, 225, 0.15); }
          @media (max-width: 991.98px) {
            .table-wrap .table tbody tr td:hover {
              background: none; } }
      .table-wrap .table tbody tr .forbidden {
        cursor: default;
        background-image: url("../assets/img/reservation/forbidden-bg.png");
        background-size: cover;
        background-color: #270d0d;
        background-repeat: repeat-x;
        background-position: left; }
        .table-wrap .table tbody tr .forbidden:hover {
          background-image: url("../assets/img/reservation/forbidden-bg.png");
          background-size: cover;
          background-color: #270d0d;
          background-repeat: repeat-x;
          background-position: left; }
          .table-wrap .table tbody tr .forbidden:hover .reservation-message {
            display: block;
            z-index: 9; }
      .table-wrap .table tbody tr:last-child td {
        border-bottom: 1px solid #3e3e3e; }
        .table-wrap .table tbody tr:last-child td:first-child {
          border-bottom: none; }
      .table-wrap .table tbody tr:nth-child(2n) td {
        border-top: 1px dashed #3e3e3e; }
        .table-wrap .table tbody tr:nth-child(2n) td:first-child {
          border-top: none; }
  .table-wrap .scroll-table {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #CD8F38;
    border-radius: 0 0 10px 10px;
    transition: all 0.3s;
    position: relative;
    margin-left: 65px;
    float: right;
    width: 95%; }
    @media (max-width: 991.98px) {
      .table-wrap .scroll-table {
        display: none; } }
    .table-wrap .scroll-table:hover {
      background-color: rgba(225, 225, 225, 0.15); }
    .table-wrap .scroll-table i {
      font-size: 25px;
      color: #CD8F38; }
    .table-wrap .scroll-table--top {
      border-radius: 10px 10px 0 0;
      display: none; }
  .table-wrap .scroll-table-wrap {
    position: absolute;
    top: 37px;
    right: 0;
    width: 100%;
    max-width: 100%; }
  .table-wrap .desktop-table .td-free:last-child .reservation-selection {
    right: 100%;
    margin-right: 10px;
    left: auto;
    margin-left: 0; }
    .table-wrap .desktop-table .td-free:last-child .reservation-selection:before {
      left: 100%;
      transform: rotate(180deg); }
    .table-wrap .desktop-table .td-free:last-child .reservation-selection:after {
      left: 100%; }
  .table-wrap .desktop-table .td-free:last-child .reservation-message {
    left: auto;
    right: 100%;
    margin-left: 0;
    margin-right: 10px; }
    .table-wrap .desktop-table .td-free:last-child .reservation-message:before {
      left: 100%;
      transform: rotate(180deg); }
  .table-wrap .desktop-table .prevTD .reservation-selection {
    right: 100%;
    margin-right: 10px;
    left: auto;
    margin-left: 0; }
    .table-wrap .desktop-table .prevTD .reservation-selection:before {
      left: 100%;
      transform: rotate(180deg); }
    .table-wrap .desktop-table .prevTD .reservation-selection:after {
      left: 100%; }
  .table-wrap .desktop-table .prevTD .reservation-message {
    right: 100%;
    left: auto; }
    .table-wrap .desktop-table .prevTD .reservation-message:before {
      right: auto;
      left: 100%;
      transform: rotate(180deg); }

.additional-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 65px;
  clear: both; }
  @media (max-width: 991.98px) {
    .additional-info {
      flex-direction: column;
      margin-left: 0; } }
  .additional-info .hall-info {
    margin-top: 10px;
    color: #fff;
    max-width: 50%;
    width: 100%; }
    @media (max-width: 991.98px) {
      .additional-info .hall-info {
        max-width: 100%;
        margin-bottom: 30px;
        text-align: center; } }
    @media (max-width: 767.98px) {
      .additional-info .hall-info p {
        font-size: 16px; } }

.forbidden-past {
  cursor: pointer !important; }

.identify {
  margin-top: 40px;
  margin-left: 65px; }
  @media (max-width: 991.98px) {
    .identify {
      margin-top: 30px;
      margin-left: 15px; } }
  .identify__item {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px; }
  .identify p {
    color: #fff; }
  .identify .byMe {
    background-color: #47CC5E; }
  .identify .byOther {
    background-color: #5F5F5F; }
  .identify .byOtherForMin {
    background-color: #fff; }
  .identify span {
    max-width: 25px;
    width: 100%;
    height: 25px;
    border-radius: 2px;
    display: block;
    margin-right: 10px; }

.popover {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translateY(-50%) translateX(-50%);
  z-index: 99999;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  white-space: normal;
  margin-top: 5px;
  padding-bottom: 20px; }
  .popover .popover-content {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .popover .clockpicker-button {
    margin: 0 auto;
    margin-top: 20px; }
  .popover .text-primary {
    color: #CD8F38; }
  .popover .clockpicker-tick:hover {
    background-color: #c0e5f7; }

.timepicker {
  text-align: center; }

.calendar .calendar-triple-js {
  display: none; }

.calendar__single {
  background-color: #fff;
  width: 100%;
  max-width: 515px;
  min-height: 700px;
  margin: auto;
  border-radius: 10px;
  padding: 40px 60px;
  text-align: center;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  @media (max-width: 991.98px) {
    .calendar__single {
      padding: 30px 10px;
      min-height: 525px;
      max-width: 400px; } }
  @media (max-width: 767.98px) {
    .calendar__single {
      padding: 40px 10px;
      max-width: 350px; } }
  @media (max-width: 575.98px) {
    .calendar__single {
      min-height: 400px; } }
  .calendar__single .select-month {
    border: 1px solid #868686;
    height: 45px;
    border-radius: 5px;
    background: none;
    outline: none;
    padding: 0 30px 0 20px;
    margin-bottom: 20px;
    background-image: url("../assets/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-size: 10px 5px;
    background-position: 90%;
    appearance: none;
    cursor: pointer;
    margin-bottom: 20px; }
    @media (max-width: 767.98px) {
      .calendar__single .select-month {
        display: none; } }
    .calendar__single .select-month option {
      cursor: pointer;
      border: 1px solid red; }
  .calendar__single--triple {
    max-width: 1385px; }
    .calendar__single--triple .calendar__date:nth-child(2) {
      margin: 0 29px; }

.calendar .inner-calendar .window-popup__close i {
  background-color: #CD8F38; }

.calendar__month-caption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px; }

.calendar__date table {
  min-height: 395px;
  margin-bottom: 100px; }
  @media (max-width: 991.98px) {
    .calendar__date table {
      min-height: 300px;
      margin-top: 30px; } }
  @media (max-width: 575.98px) {
    .calendar__date table {
      min-height: 250px;
      margin-top: 30px; } }
  .calendar__date table tr {
    height: 67px; }
    @media (max-width: 991.98px) {
      .calendar__date table tr {
        height: 30px; } }
  .calendar__date table td {
    width: 45px; }
    @media (max-width: 991.98px) {
      .calendar__date table td {
        width: 15px; } }
    .calendar__date table td a {
      color: #5a5a5a;
      max-width: 45px;
      height: 45px;
      width: 100%;
      margin: auto;
      border-radius: 10px;
      background-color: transparent;
      font-weight: 400;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 991.98px) {
        .calendar__date table td a {
          height: 30px;
          width: 30px;
          border-radius: 5px; } }
      @media (max-width: 575.98px) {
        .calendar__date table td a {
          font-size: 16px;
          line-height: 14px; } }
    .calendar__date table td .active,
    .calendar__date table td .hover-date {
      background-color: #CD8F38;
      color: #fff; }
  .calendar__date table thead {
    margin-bottom: 40px; }
    .calendar__date table thead tr td {
      width: 64px;
      color: #a5a5a5; }
      @media (max-width: 991.98px) {
        .calendar__date table thead tr td {
          width: 40px; } }

.calendar__month-arrow {
  color: #c4c4c4;
  font-size: 20px;
  transition: all 0.3s; }
  .calendar__month-arrow:hover {
    color: #CD8F38; }
  @media (max-width: 575.98px) {
    .calendar__month-arrow {
      font-size: 16px;
      color: #CD8F38; }
      .calendar__month-arrow:hover {
        color: #c4c4c4; } }

.calendar__date-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start; }

.calendar .btn-wrap {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%); }

.modal-opena {
  overflow: hidden;
  padding-right: 15px; }
  .modal-opena .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.weekend {
  background-color: transparent !important;
  color: red !important;
  pointer-events: none; }

.inner-page--main-page--ipad {
  position: relative; }
  .inner-page--main-page--ipad .reservation-selection {
    transform: translateX(-50%);
    left: auto;
    right: 50%; }
  .inner-page--main-page--ipad .reservation-selection {
    top: 200% !important;
    right: auto !important;
    z-index: 999 !important;
    min-width: auto !important;
    max-width: 270px !important;
    width: 100% !important;
    margin: 0 auto !important;
    left: 50% !important; }
    .inner-page--main-page--ipad .reservation-selection:before, .inner-page--main-page--ipad .reservation-selection:after {
      display: none; }
  .inner-page--main-page--ipad .reservation-message {
    left: 50% !important;
    top: 110% !important;
    transform: translateX(-50%) !important;
    min-width: 100% !important;
    width: 100% !important;
    text-align: center !important;
    margin: 0 !important;
    padding: 10px !important;
    margin-top: 5px !important; }
    .inner-page--main-page--ipad .reservation-message p {
      font-size: 14px !important; }
    .inner-page--main-page--ipad .reservation-message:before {
      border-top: 10px solid transparent !important;
      border-right: 20px solid rgba(0, 0, 0, 0.8) !important;
      border-bottom: 10px solid transparent !important;
      transform: rotate(90deg) !important;
      left: 50% !important;
      bottom: 100% !important;
      top: auto !important; }
  .inner-page--main-page--ipad .reservation__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
    position: relative;
    padding-top: 40px; }
    @media (max-width: 575.98px) {
      .inner-page--main-page--ipad .reservation__header {
        padding-bottom: 20px; } }
    .inner-page--main-page--ipad .reservation__header .reservation__reservation-date {
      margin: 0;
      justify-content: center;
      position: relative; }
    .inner-page--main-page--ipad .reservation__header .reservation__calendar {
      width: 100%; }
    .inner-page--main-page--ipad .reservation__header .reservation__day-slide {
      position: absolute;
      margin-right: 0;
      display: flex;
      justify-content: space-around;
      width: 100%; }
      @media (max-width: 767.98px) {
        .inner-page--main-page--ipad .reservation__header .reservation__day-slide {
          justify-content: space-between; } }
      @media (max-width: 575.98px) {
        .inner-page--main-page--ipad .reservation__header .reservation__day-slide {
          display: none; } }
    .inner-page--main-page--ipad .reservation__header .date-choose-js {
      position: relative;
      z-index: 999; }
  .inner-page--main-page--ipad .ipad-title {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 15px;
    color: #fff;
    font-size: 30px; }
  .inner-page--main-page--ipad .dropdown__select {
    position: absolute;
    right: 0;
    top: 0; }
    .inner-page--main-page--ipad .dropdown__select .lang {
      padding-top: 0; }
      .inner-page--main-page--ipad .dropdown__select .lang:after {
        transform: translateY(-83%); }
  @media (max-width: 991.98px) {
    .inner-page--main-page--ipad .reservation__panel-control {
      display: block; } }
  .inner-page--main-page--ipad .current-day {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(196, 196, 196, 0.5); }
