#reservation,
#unfixed-reservation,
#sleep-capsule {
  .modal-wrap {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }
}

.modal-wrap {
  padding: 15px;
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}

.window-popup {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
  max-width: 458px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  @include media-breakpoint-down(lg) {
    padding: 50px;
  }

  @include media-breakpoint-down(md) {
    padding: 40px;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px;
  }

  &__close {
    background-size: contain;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    right: -66px;
    top: 40px;
    cursor: pointer;
    transition: $transition;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(lg) {
      right: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
    }

    i {
      max-width: 100%;
      width: 100%;
      height: 2px;
      background-color: $wc;
      display: block;
      position: absolute;

      @include media-breakpoint-down(lg) {
        background-color: $accent-color;
      }

      &:first-child {
        transform: rotate(45deg);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    }

    &:hover {
      transform: rotate(-90deg);

      i {
        background-color: $accent-color;
      }
    }
  }


  &__input {
    width: 100%;
    max-width: 100%;
    position: relative;
    margin-top: 35px;

    input {
      width: 100%;
      max-width: 100%;
    }
  }

  &__password-recovery {
    color: #A5A5A5;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: block;
    text-align: right;
    width: 100%;
    margin-top: 10px;
    transition: $transition;

    &:hover {
      color: $accent-color;
    }
  }


  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;

    //.label {
    //  transition: $transition;
    //  margin: 0;
    //  color: #868686;
    //  font-size: 18px;
    //  line-height: 22px;
    //  position: absolute;
    //  top: 0;
    //
    //  &.filled {
    //    font-size: 12px;
    //    line-height: 14px;
    //    transform: translateY(-12px);
    //  }
    //
    //  &.focused {
    //    color: $bc;
    //  }
    //}

    input,
    textarea {
      border: none;
      border-bottom: 1px solid #A5A5A5;
      outline: none;
      color: #868686;
      background-color: transparent;
      z-index: 1;
      position: relative;

      &:focus {
        border-color: $bc;
        color: $bc;
      }
    }


  }

  .caption {
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: $bc;

    &--centralized {
      text-align: center;
      margin-bottom: 22px;
    }
  }

  .btn {
    margin-top: 35px;
  }


  &__caption {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid $accent-color;
    position: relative;
    padding-right: 20px;

    &--main-caption-reservation {
      @include media-breakpoint-down(xs) {
        justify-content: center;
      }
    }
  }

  &__conference-hall {
    width: 100%;
    margin-bottom: 47px;
    margin-top: 40px;
    column-count: 3;
    list-style-type: none;

    @include media-breakpoint-down(lg) {
      margin-bottom: 35px;
      margin-top: 35px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      margin-top: 30px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;
      margin-top: 25px;
    }
  }

  &__type-of-seating {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 45px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 35px;
      margin-top: 35px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      margin-top: 30px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;
      margin-top: 25px;
    }

    @include media-breakpoint-down(xs) {
      margin: 15px 0;
    }

  }

  &__type-of-seating-item {
    margin-bottom: 20px;

    @include media-breakpoint-down(xs) {
      padding: 0 5px;
    }

    img {
      width: 100%;
      max-width: 100%;
    }

    label {
      cursor: pointer;
      position: relative;
      margin: 0;

      .track-pointer,
      .type-of-seating {
        position: absolute;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        color: #756866;
        text-align: center;

        @include media-breakpoint-down(lg) {
          font-size: 12px;
        }

        @include media-breakpoint-down(md) {
          font-size: 11px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 11px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 10px;
          text-transform: none;
        }
      }

      .track-pointer {
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        max-width: 100%;

        @include media-breakpoint-down(xs) {
          font-size: 10px;
          top: 0;
          text-transform: none;
        }
      }

      .type-of-seating {
        transform: translateY(-50%) rotate(-90deg);
        background-color: #ffb800;
        display: inline-block;
        color: $bc;
        padding: 2px 5px;
        line-height: normal;
        top: 50%;
        left: -11px;
        font-size: 11px;
        font-weight: 400;
      }

      .img-type-of-seating {
        border-radius: 5px;
        border: 1px solid #868686;
        display: block;
        position: relative;


      }
    }

    .type-of-seating-input {
      position: absolute;
      opacity: 0;

      &:checked+label .img-type-of-seating {
        border: 1px solid $accent-color;
        box-shadow: 0 0 2px 2px $accent-color;
      }

      &:checked+label .type-of-seating-name {
        color: $accent-color;
      }
    }

    .type-of-seating-name {
      text-align: left;
      margin-top: 5px;

      @include media-breakpoint-down(lg) {
        font-size: 16px;
      }

      @include media-breakpoint-down(md) {
        font-size: 15px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }

    .download-type {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $accent-color;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      cursor: pointer;

      &__desc {
        position: absolute;

        img {
          width: auto;
          margin: auto;
        }

        span {
          display: block;
          margin-top: 5px;

          @include media-breakpoint-down(md) {
            font-size: 13px;
            line-height: normal;
          }

        }
      }
    }
  }


  &__reservation-hours {
    position: relative;

    input {
      max-width: 60px !important;
      width: 100%;
      border-bottom: 1px solid #A5A5A5;
      color: #a5a5a5;
      position: relative;
      text-align: center;
    }
  }

  &__reservation-date-wrap {
    width: 100%;
    margin: 40px 0 50px;

    @include media-breakpoint-down(xs) {
      margin: 40px 0 36px;
    }

    p {
      color: $bc;
      display: inline-block;
      //margin-right: 10px;
      position: relative;
    }
  }

  &__reservation-item-wrap {
    margin-bottom: 20px;
  }

  &__reservation-item {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    flex-basis: 0;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    &--unfixed {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include media-breakpoint-down(xs) {
        display: flex;
      }
    }

  }

  .add-more {
    color: $accent-color;
    margin-left: 60px;

    @include media-breakpoint-down(md) {
      margin-left: 10px;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 30px;
    }

    &--unfixed {
      margin: 0;
      margin-top: 10px;
      display: inline-block;
    }
  }

  .date-format {
    color: $bc;
    margin-right: 18px;

    &:active,
    &:focus {
      color: $bc;
    }
  }

  .checkWrap {}

  &__reservation-date {
    line-height: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .window-popup__unfixed-reservation {
      margin-right: 54px;

      @include media-breakpoint-down(sm) {
        margin-right: 20px;
      }

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    p {
      margin-top: 0;
      margin-right: 10px;

      img {
        width: 20px;
        position: absolute;
        left: -30px;
        bottom: 0;
      }
    }
  }

  &__date-img {
    max-width: 20px;
    margin-right: 10px;
    display: inline-block;

    img,
    svg {
      width: 100%;
      max-width: 100%;
    }
  }

  &__date {
    border-bottom: 1px solid $bc;
    cursor: pointer;
  }

  &__reservation-date-choose {
    margin-left: 30px;
    position: relative;
    font-size: 16px;
    line-height: 18px;
    color: #868686;
    margin-top: 22px;
    margin-bottom: 0;

    //text-align: center;
    &:hover {
      color: $accent-color;

      &:after {
        width: 0;
        background-color: $accent-color;
      }
    }

    &:after {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background-color: $bc;
      left: 0;
      bottom: 0;
      transition: $transition;
    }
  }

  &__reservation-hours {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
      margin: 25px 0 25px 20px;
    }

    p {
      margin: 0 10px;
    }


  }

  &__contact-details {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 50px;

    .window-popup__input {
      max-width: 420px;
      display: inline-block;
      margin-right: 75px;

      @include media-breakpoint-down(lg) {
        margin-right: 60px;
      }

      @include media-breakpoint-down(md) {
        margin-right: 60px;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  &__additionally {
    display: none;
    width: 100%;
  }

  &__additionally-list {
    margin-top: 40px;
  }

  &__additionally-item {
    margin-bottom: 40px;
  }

  &__additionally-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $bc;

    .agreement {
      margin: 0;
    }


    &--address {
      @include media-breakpoint-down(xs) {
        flex-direction: column;
        align-items: flex-start;
      }

      label {
        margin: 0;
      }

      textarea {
        border: none;
        resize: none;
        border-bottom: 1px solid #a5a5a5;
        height: 35px;
        outline: none;
        margin-left: 20px;
        width: 100%;
        max-width: 50%;
        font-size: 14px;
        padding: 5px;
        vertical-align: middle;
        display: flex;

        @include media-breakpoint-down(xs) {
          margin: 0;
          max-width: 100%;
          padding: 0;
          margin-top: 10px;
        }
      }
    }
  }

  &__additionally-product-total {
    display: flex;
    justify-content: space-between;
  }

  &__more {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &:hover {
      .window-popup__more-img {
        &:before {
          opacity: 1;
        }
      }
    }

    p {
      font-weight: 700;
      font-size: 22px;
      line-height: 25px;
      color: $bc;
      margin-top: 14px;
      margin-bottom: 0;
    }


  }

  &__more-img {
    width: 100%;
    margin-top: 40px;
    display: block;
    position: relative;

    @include media-breakpoint-down(xs) {
      margin-top: 30px;
    }

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: $transition;
    }

    img {
      width: 100%;
    }
  }

  &--reservation {
    max-width: 1000px;
    padding: 60px 40px;

    @include media-breakpoint-down(lg) {
      padding: 40px;
    }

    @include media-breakpoint-down(md) {
      padding: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding: 15px 15px 45px;
    }

    @include media-breakpoint-down(xs) {
      border-radius: 0;
    }
  }

  &__description {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }

    ul {
      column-count: 2;
      margin-top: 15px;

      @include media-breakpoint-down(xs) {
        column-count: 1;
      }

      li {
        position: relative;
        list-style-type: disc;
        margin-left: 20px;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 22px;

        @include media-breakpoint-down(lg) {
          font-size: 17px;
          line-height: 21px;
        }

        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 20px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 15px;
          line-height: 19px;
        }
      }
    }
  }

  &__slider-wrap {
    position: relative;
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(xs) {
      padding-bottom: 20px;
    }

    .slick-list {
      width: 100%;
      padding-top: 5px;
    }

    .slider-arrows {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      flex-direction: row;
      display: none;

      @include media-breakpoint-down(xs) {
        display: flex;
      }

      i {
        font-size: 20px;
        transition: .3s;
      }

      .arrow {
        &-left {
          transform: translateX(-100%);
        }

        &-right {
          transform: translateX(100%);
        }

        &-left,
        &-right {
          cursor: pointer;

          &:hover {
            color: $accent-color;
          }
        }
      }
    }
  }

  &__time-reserving {
    column-count: 6;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      column-count: 5;
    }

    @include media-breakpoint-down(sm) {
      column-count: 4;
    }

    @include media-breakpoint-down(xs) {
      column-count: 3;
    }


    label {
      white-space: nowrap;

      &:before {
        top: 53%;
        width: 22px;
        height: 22px;
      }

      &:after {
        left: -32px;
      }
    }


    .inaccessible {
      pointer-events: none;

      label {
        color: rgba(#000000, 0.2);

        &:before {
          border-color: rgba(#000000, 0.2);
        }
      }
    }
  }

  &__time-reserving-status {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .agreement {

      label {
        &:before {
          top: 53%;
          width: 22px;
          height: 22px;
        }

        &:after {
          left: -32px;
        }
      }
    }

  }

  &--capsule {
    max-width: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    position: relative;

    form {
      align-items: flex-start;
    }

    .capsule-container {
      max-width: 1000px;
      width: 100%;

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 100%;
      }
    }

    .service__total-wrap {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        bottom: 40px;
        background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 10px 1px;
        background-repeat: repeat-x;
      }
    }

    .service__total-cap,
    .service__total {
      position: relative;
      background-color: #ffffff;
    }

    .pay {
      width: 100%;
      display: flex;
      align-items: center;

      .btn {
        margin-top: 0;
      }
    }

    .window-popup__contact-details .window-popup__input {
      @include media-breakpoint-down(sm) {
        margin-right: 0 !important;
      }
    }
  }
}

.authorization-window {
  max-height: 376px;
}

.trigger-js {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 0;
  cursor: pointer;
  color: $accent-color;
  font-size: 27px;
  display: block;
  transition: $transition;
}

.rotate-arrow {
  transform: rotate(180deg);
}


.close-btn-triple {
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;

  i {
    background-color: $accent-color;
  }
}

.notice-wrap {
  position: absolute;
  background-color: rgba(#FFFFFF, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(xs) {
    align-items: flex-start;
    padding-top: 50px;
  }

  .notice-window {
    padding: 20px;
    max-width: 515px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(xs) {
      max-width: 400px;
      padding: 15px;
    }

    h3 {
      text-align: center;
      margin-bottom: 10px;
    }

    br {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    .btn {
      @include media-breakpoint-down(xs) {
        font-size: 13px;
      }
    }

    .delete-btn {
      margin-top: 10px;
      color: $accent-color;
      display: block;
    }
  }
}

.capsule-timeWrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .capsule-img {
    max-width: 480px;
    width: 100%;
    margin: 40px 0 50px;

    @include media-breakpoint-down(md) {
      margin: 0 0 30px;
    }

    img {
      width: 100%;
    }
  }
}