.history, .purchases {
  .table {
    margin-top: 17px;
    margin-bottom: 40px;
    border-bottom: 1px solid #868686;

    thead {
      th {
        border-top: none;
        border-bottom: 1px solid $accent-color;
        font-weight: 500;
        font-size: 18px;
        padding: 20px;
        @include media-breakpoint-down(lg) {
          padding: 15px;
          font-size: 14px;
        }
        @include media-breakpoint-down(md) {
          padding: 10px;
          font-size: 13px;
        }
        @include media-breakpoint-down(sm) {
          padding: 5px;
          font-size: 12px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 10px;
        }

        &:last-child {
          text-align: right;
          @include media-breakpoint-down(xs) {
            text-align: left;
          }
        }
      }
    }

    td {
      font-weight: 400;
      font-size: 18px;
      border-top: 1px solid #868686;
      padding: 20px;
      vertical-align: middle;
      @include media-breakpoint-down(lg) {
        padding: 15px;
        font-size: 16px;
      }
      @include media-breakpoint-down(md) {
        padding: 10px;
        font-size: 15px;
      }
      @include media-breakpoint-down(sm) {
        padding: 5px;
        font-size: 14px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 8px;
      }

      &:last-child {
        text-align: right;
        @include media-breakpoint-down(xs) {
          text-align: left;
        }
      }
    }
  }
}

.purchases {
  .table {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  td {
    &:last-child {
      width: 40%;
      @media (max-width: 1600.98px) {
        width: 30%;
      }
      @include media-breakpoint-down(lg) {
        padding-left: 0;
        width: auto;
      }

      .btn {
        float: right;
        padding: 0 40px;
        @include media-breakpoint-down(md) {
          font-size: 13px;
          min-width: 200px;
          padding: 0 10px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 11px;
          min-height: 35px;
          min-width: 150px;
          padding: 0 5px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 8px;
          min-height: 30px;
          min-width: 0;
          padding: 0;
          white-space: normal;
          background-color: transparent;
          color: $accent-color;
          border: none;
          text-align: center;
        }
      }
    }


  }

  .tabs {
    margin-top: 55px;
    margin-bottom: 30px;
    @include media-breakpoint-down(xs) {
      border-bottom: 1px solid #CD8F38;
      padding-bottom: 10px;
      margin-bottom: 0;
      margin-top: 10px;
    }

    &__item {
      display: inline-block;
      margin-right: 30px;


      a {
        font-weight: 700;
        font-size: 22px;
        line-height: 25px;
        color: #868686;
        @include media-breakpoint-down(lg) {
          font-size: 20px;
          line-height: 23px;
        }
        @include media-breakpoint-down(md) {
          font-size: 18px;
          line-height: 21px;
          font-weight: 500;
        }
        @include media-breakpoint-down(sm) {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    .active {
      a {
        color: $accent-color;
      }
    }
  }
}


.irrevocable {
  pointer-events: none;
  background-color: rgba($accent-color, 0.4);
  border: 1px solid rgba($accent-color, 0.1);
  position: relative;
}

.mobile-table-purchases {
  display: none;
  margin-bottom: 50px;
  @include media-breakpoint-down(xs) {
    display: block;
  }

  .purchases-item {
    padding: 15px 0;
    border-bottom: 1px solid $accent-color;

    &:last-child {
      border-bottom: none;
    }

    span {
      color: #a5a5a5;
      font-size: 14px;
      line-height: 16px;
    }

    h3 {
      font-weight: 700;
    }
  }

  .purchases-date-time {
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .purchases-control-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: $accent-color;
    }

    .btn {
      font-size: 16px;
      line-height: 18px;
      min-height: 35px;
      padding: 0 20px;
    }
  }
}

.btnWrap {
  position: relative;
  display: inline-flex;
  align-items: center;
  .btn {
    @include media-breakpoint-down(xs) {
      font-size: 13px !important;
    }
  }

  span {
    max-width: 20px;
    width: 100%;
    display: inline-block;
    margin-right: 10px;
    // position: absolute;
    // right: 10px;
    // z-index: 99999999;
    // top: 50%;
    // transform: translateY(-50%);
    @include media-breakpoint-down(sm) {
      max-width: 14px;
      right: 6px;
    }
    @include media-breakpoint-down(xs) {
      right: 10px;
    }

    img {
      width: 100%;
    }

    &:hover {
      cursor: pointer;

      .info-window {
        display: block;
      }
    }
  }

  .info-window {
    position: absolute;
    background: $wc;
    border-radius: 5px;
    padding: 5px 10px;
    min-width: 100px;
    text-align: center;
    box-shadow: 1px 2px 10px 1px rgba(#000, 0.3);
    bottom: 120%;
    left: 0;
    display: none;

    p {
      margin-bottom: 4px;
      font-size: 14px;
      line-height: normal;
    }

  }
}