.service {
  padding-top: 35px;

  &__selected {
    border-bottom: 1px solid #cd8f38;
    padding-bottom: 30px;
    margin-top: 40px;
    position: relative;

    &:first-child {
      margin-top: 0 !important;
    }
  }


  &__selected-info {
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }

    h2 {
      color: $bc;
      margin-bottom: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }

    .additionally {
      margin-top: 20px;
      @include media-breakpoint-down(xs) {
        margin-top: 15px;
      }
    }
  }

  &__selected-date {
    display: flex;
    align-items: flex-start;
    max-width: 500px;
    width: 100%;
    margin-bottom: 30px;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }

    span {
      font-size: 18px;
      line-height: 22px;
      color: $bc;
      font-weight: 400;
      @include media-breakpoint-down(sm) {
        font-size: 15px;
      }
    }
  }

  &__sub-caption {
    margin: 0;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px;
    color: $bc;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 16px;
      font-weight: 400;
    }

    &--small {
      margin-top: 20px;
      font-size: 18px;
      line-height: 22px;
      color: $bc;
      font-weight: 500;
    }
  }

  &__img {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: calc(100% - 30px);
    position: relative;
    padding-bottom: 30px;
    @include media-breakpoint-down(xs) {
      align-items: center;
      margin-top: 40px;
      padding-bottom: 0;
    }
  }

  &__selected-total {
    text-align: right;
    color: #868686;
    @include media-breakpoint-down(xs) {
      position: absolute;
      top: 0;
    }
  }

  &__img-wrap {
    max-width: 480px;
    width: 100%;
    @include media-breakpoint-down(xs) {
      max-width: 100%;
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  &__img-control {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
    width: 100%;
    max-width: 100%;
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: space-between;
      max-width: 200px;
      align-items: center;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 20px;
      max-width: 100%;
    }

    a {
      cursor: pointer;
      color: $accent-color;
      margin-left: 38px;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        font-size: 13px;
        margin: 0;
      }
      @include media-breakpoint-down(xs) {
        font-size: 16px;
        color: $bc;
      }

      &:first-child {
        margin-left: 0;
        @include media-breakpoint-down(xs) {
          border: 1px solid $accent-color;
          border-radius: 30px;
          padding: 10px 15px;
          color: $accent-color;
          &:hover {
            box-shadow: 0 8px 16px 0 rgba(205, 143, 56, .24);
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }


  &__total-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 13px;
    width: 100%;
    margin-bottom: 20px;

    p {
      font-size: 32px;
      line-height: 37px;
      margin: 0;
      font-weight: 700;
      text-align: right;
      @include media-breakpoint-down(xs) {
        font-size: 26px;
        line-height: 30px;
      }
    }

    &--reservation {
      border-bottom: 1px solid $accent-color;
      padding-bottom: 11px;
      padding-top: 60px;
      margin-bottom: 0;
    }
  }

  &__total {
    p {
      color: $accent-color;
    }

    .change-currency {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-top: 1px;

      &__item {
        display: inline-block;
        margin-right: 9px;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          color: #868686;

          &:hover {
            color: $accent-color;
          }
        }

        &--active {
          a {
            color: $accent-color;
          }
        }
      }
    }
  }

  &__empty-list {
    text-align: center;

    h2 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 52px;
    }

    a {
      color: $accent-color;
    }
  }

  .additional-information {
    p {
      margin-top: 7px;
      text-align: center;
    }
  }
}



