body {
  font-family: $font-family-sans-serif;
  background-color: $bc;
}

h1 {
  font-size: $h1Fz;
  line-height: 37px;
  font-weight: 700;

  @include media-breakpoint-down(lg) {
    font-size: 25px;
    line-height: 30px;
  }

  @include media-breakpoint-down(md) {
    font-size: 23px;
    line-height: 28px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 18px;
    line-height: 23px;
  }
}

h2 {
  font-size: $h2Fz;
  line-height: 30px;
  font-weight: 700;

  @include media-breakpoint-down(xs) {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
}

h3 {
  font-weight: 700;
  font-size: $h3Fz;
  line-height: 25px;

  @include media-breakpoint-down(xs) {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
}

h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

h5 {
  font-size: 14px;
  text-align: left;
  display: block;
  width: 100%;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 0;
}

input {
  border-radius: 0;
}

.modal-description {
  color: #868686;
  text-align: center;
}

.check-mail-window {
  .btn {
    margin: 0 auto;
  }
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  @include media-breakpoint-down(sm) {
    font-size: 15px;
    line-height: 18px;
  }

  @include media-breakpoint-down(xs) {
    font-size: 14px;
    line-height: 18px;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

a {
  transition: $transition;

  &:hover {
    text-decoration: none;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}


//inner-page
.inner-page {
  background-color: #fff;
  padding-top: 13px;
  padding: 40px;
  padding-top: 70px;
  min-height: 100vh;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 20px 15px;
  }

  &--main-page {
    background-color: #1c1c1c;
    height: 100%;

    @include media-breakpoint-down(xs) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

//inner-page end

//caption
.caption-wrap {
  border-bottom: 1px solid $accent-color;
  padding-bottom: 19px;
  display: flex;
  justify-content: center;

  h1 {
    color: $bc;
    width: 100%;
    text-align: center;
    padding-left: 80px;

    @include media-breakpoint-down(xs) {
      padding-left: 15px;
    }
  }

  &--press-left {
    justify-content: space-between;
    padding-left: 0;

    h1 {
      padding-left: 0;
      width: auto;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }
  }
}

//caption end

//return-button
.return-button {
  position: absolute;
  top: 75px;
  left: 40px;

  @include media-breakpoint-down(md) {
    top: 23px;
    left: 15px;
  }

  @include media-breakpoint-down(sm) {
    top: 20px;
  }

  @include media-breakpoint-down(xs) {
    top: 32px;
  }

  a {
    color: $accent-color;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    position: relative;
    padding-left: 26px;

    @include media-breakpoint-down(xs) {
      padding-left: 0;
    }

    &:before {
      position: absolute;
      content: '';
      font-family: 'icon';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 10.5px;
      height: 21px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 24px;
      color: $accent-color;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }

    span {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }
}

//return-button end


//btn
.btn-wrap {
  display: inline-block;

  &--windowPopup {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .btn {
      color: #ffffff !important;

      &:hover {
        color: $accent-color !important;
      }
    }
  }
}

.btn {
  background-color: $accent-color;
  color: $wc;
  outline: none;
  white-space: nowrap;
  min-width: 174px;
  min-height: 45px;
  border-radius: 30px;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: inherit;
  border: 1px solid $accent-color;
  transition: $transition;
  padding: 0 25px;

  &:hover {
    background-color: transparent;
    color: $accent-color;
    box-shadow: 0 8px 16px 0 rgba(205, 143, 56, .24);
  }

  &--table-reservation {
    max-width: 180px;
    min-width: auto;
    margin: auto;

    @media (max-width: 1619.98px) {
      font-size: 14px;
    }

    @media (max-width: 1299.98px) {
      min-height: 35px;
      font-size: 12px;
    }

    &:hover {
      box-shadow: none;
      background-color: #8a8484;
      border-color: #8a8484;
      color: #ffffff;
    }
  }

  &--table-reservation-cancel {
    max-width: 180px;
    min-width: auto;
    margin: auto;
    margin-top: 10px;
    background-color: #DC2222;
    border-color: #DC2222;

    &:hover {
      background-color: #8a8484;
      color: #ffffff;
      box-shadow: none;
      border-color: #8a8484;
    }

    @media (max-width: 1619.98px) {
      font-size: 14px;
    }

    @media (max-width: 1299.98px) {
      min-height: 35px;
      font-size: 12px;
    }
  }

  &--additional-btn {
    margin-top: 5px !important;
  }
}

//return-btn pay
.return-btn {
  margin-right: 30px;
  color: $bc;
  font-size: 18px;
  line-height: 21px;
  position: relative;

  @include media-breakpoint-down(xs) {
    margin-right: 0;
  }

  &:hover {
    color: $accent-color;

    &:after {
      width: 0;
      background-color: $accent-color;
    }
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    height: 1px;
    background-color: $bc;
    transition: $transition;
  }
}

//return-btn pay end

//btn end

//pagination
.pagination {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &__item {
    display: inline-block;
    color: #868686;
    margin-right: 15px;

    a {
      color: #868686;

      &:hover {
        color: $accent-color;
      }
    }

    &--active {
      a {
        color: $accent-color;
        cursor: default;
      }
    }
  }
}

//pagination end


//user
.affiliation-user {
  h2 {
    color: #868686;
    font-weight: 700;
    margin: 0;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      margin-top: 5px;
      line-height: 18px;
      font-weight: 400;
    }
  }
}

//user end

.booked {
  filter: grayscale(100%);
  opacity: .2;
  pointer-events: none;
}

.agreement {
  display: flex;
  justify-content: center;
  position: relative;

  @include media-breakpoint-down(sm) {
    padding-left: 45px;
  }

  input {
    margin-right: 27px;
    opacity: 0!important;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    position: absolute !important;

    &:checked+label:before {
      border: 1px solid $accent-color;
    }

    &:checked+label:after {
      opacity: 1;
      transform: translateY(-70%) rotate(-40deg);
    }
  }

  label {
    color: #868686;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    margin: 0;
    margin-right: 5px;
    position: relative;
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      width: 25px;
      height: 25px;
      left: -44px;
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid #E5E5E5;
    }

    &:after {
      position: absolute;
      content: '';
      width: 15px;
      height: 8px;
      border-bottom: 2px solid $accent-color;
      border-left: 2px solid $accent-color;
      left: -39px;
      top: 50%;
      transform: rotate(45deg) scale(0.2);
      opacity: 0;
      transition: $transition;
    }

    a {
      color: #868686;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      text-decoration: underline;

      &:hover {
        color: $accent-color;
        text-decoration: none;
      }
    }

  }


  &--reservation-hall {
    display: block;
    margin-bottom: 30px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 25px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }

    input {
      position: absolute;
    }

    label {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: $bc;
      padding-left: 36px;
      white-space: nowrap;

      @include media-breakpoint-down(lg) {
        padding-left: 34px;
        font-size: 17px;
      }

      @include media-breakpoint-down(md) {
        padding-left: 32px;
        font-size: 16px;
      }

      @include media-breakpoint-down(sm) {
        padding-left: 30px;
        font-size: 16px;
      }

      @include media-breakpoint-down(xs) {
        padding-left: 20px;
        font-size: 14px;
        font-weight: 400;
      }

      span {
        color: #A5A5A5;
      }

      &:before {
        left: 0;

        @include media-breakpoint-down(sm) {
          width: 20px;
          height: 20px;
        }

        @include media-breakpoint-down(xs) {
          width: 17px;
          height: 17px;
        }
      }

      &:after {
        left: 5px;

        @include media-breakpoint-down(sm) {
          width: 11px;
          height: 5px;
        }

        @include media-breakpoint-down(xs) {
          width: 8px;
          height: 4px;
          border-width: 1px;
        }
      }
    }
  }

  &--windowPopup {
    label {
      color: $bc;
    }
  }

  &--time-reserving {
    margin-bottom: 10px;
    display: block;
    padding-left: 36px;
    break-inside: avoid;

    label {
      color: #000000;

      &:before {
        left: -36px;
        border-color: #000000
      }

      &:after {
        left: -30px;
      }
    }
  }

  &--time-reserving.reserved {
    pointer-events: none;

    label {

      &:before,
      &:after {
        border-color: #868686 !important;
      }

      &:before {
        border-color: rgba(#868686, 0.3) !important;
      }
    }
  }

  &--status-byMe {
    input {
      &:checked+label:before {
        border: 1px solid #37BE21;
      }

      &:checked+label:after {
        border-color: #37BE21;
      }
    }

  }

  &--by-anotherCustomer {
    input {
      &:checked+label:before {
        border: 1px solid #DC2222;
      }

      &:checked+label:after {
        border-color: #DC2222;
      }
    }

  }

  &--by-anotherCustomer-minute {
    input {
      &:checked+label:before {
        border: 1px solid #FFB800;
      }

      &:checked+label:after {
        border-color: #FFB800;
      }
    }
  }

  &--by-anotherCustomer-minute,
  &--by-anotherCustomer,
  &--status-byMe {
    pointer-events: auto;
  }
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;

}

input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}


.quantity-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 26px;
  justify-content: center;

  @include media-breakpoint-down(md) {
    margin-right: 0;
  }

  input {
    opacity: 1;
    position: relative;
    max-width: 50px;
  }

  //&--arrival {
  //  justify-content: center;
  //
  //  p {
  //    margin: 0;
  //  }
  //}

  //inpu num
  &__wrap {
    display: flex;
    align-items: center;

    input {
      text-align: center;
    }

    .plus,
    .minus {
      position: absolute;
      width: 10px;
      height: 10px;
      cursor: pointer;

      &:before,
      &:after {
        position: absolute;
        content: '';
        background-color: $accent-color;
      }
    }

    .plus {
      right: 0;

      &:before,
      &:after {
        height: 1px;
        width: 10px;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      &:after {
        transform: translateY(-50%) translateX(-50%) rotate(90deg);
      }

      @include media-breakpoint-down(md) {
        right: 10px;
      }
    }

    .minus {
      left: 0;

      &:before {
        height: 1px;
        width: 10px;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }

      @include media-breakpoint-down(md) {
        left: 10px;
      }
    }
  }

  //inpu num end

}

.pay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }

  &--reservation {
    margin-bottom: 60px;
    margin-top: 0;
  }

  .order-1 {
    margin-bottom: 20px;
  }
}


.effect-underline {
  position: relative;
  transition: $transition;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: $accent-color;
    transition: $transition;
    left: 0;
    bottom: 0;
  }

  &:hover {
    color: $accent-color;

    &:before {
      width: 0;
      background-color: $bc;
    }
  }

  &--white {
    &:before {
      background-color: $wc;
    }

    &:hover {
      &:before {
        background: $accent-color;
      }
    }
  }

  &--back {
    &:before {
      width: 0;
    }

    &:hover {
      &:before {
        width: 100%;
        background: $accent-color;
      }
    }
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//.reservation__header, .table-wrap__table-header, .scroll-table {
//  margin-left: 3.9%;
//}

.modal {
  padding: 0 !important;
}

form {
  .input-js {
    .label {
      transition: $transition;
      margin: 0;
      color: #868686;
      font-size: 18px;
      line-height: 22px;
      position: absolute;
      top: 0;

      &.filled {
        font-size: 12px;
        line-height: 14px;
        transform: translateY(-12px);
      }

      &.focused {
        color: $bc;
      }
    }
  }

  //input {
  //  border: none;
  //  border-bottom: 1px solid #A5A5A5;
  //  outline: none;
  //  color: #868686;
  //  background-color: transparent;
  //  z-index: 1;
  //  position: relative;
  //
  //  &:focus {
  //    border-color: $bc;
  //    color: $bc;
  //  }
  //}
}


.inside-nav {
  .reservation__header {
    padding-bottom: 0;
  }

  .authorization {
    a {
      color: $accent-color !important;

      &:hover {
        color: $bc !important;
      }
    }
  }

  .dropdown-menu {
    background-color: $wc !important;

    a {
      &:hover {
        color: #000000 !important;
      }
    }
  }

  .dropdown__select,
  .lang {
    color: $accent-color;
    background-color: $wc !important;

    .select-item {
      border: 1px solid #CD8F38;
      border-top: none;

      li {
        color: $accent-color;
        background-color: $wc !important;

        &:hover {
          color: $bc !important;
        }
      }
    }
  }
}

.buy-more {
  max-width: 940px;
  width: 100%;
  margin: auto;
  margin-top: 50px;

  h1 {
    @include media-breakpoint-down(md) {
      text-align: center;
    }

    @media (max-width: 459.98px) {
      text-align: left;
    }
  }

  .window-popup__more-img {
    margin-top: 20px;

    @media (max-width: 459.98px) {
      margin-top: 10px;
    }
  }

  .window-popup__more {
    @include media-breakpoint-down(md) {
      max-width: 400px;
      width: 100%;
      margin: auto;
      margin-bottom: 3px;
    }

    p {
      font-size: 18px;
      font-weight: 400;
    }
  }

}



.dropdown {
  font-weight: 500;
  z-index: 1;

  &__select {
    margin: 0;
    position: relative;
    background-color: #1c1c1c;
    color: $wc;
    z-index: 9;

    .active {
      color: $accent-color;
    }

    i {
      margin-left: 0;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 9px;
      cursor: pointer;
      transition: $transition;

      &:hover {
        cursor: pointer;
        color: $accent-color;
      }
    }

    .lang {
      text-align: center;
      background: #1c1c1c;
      display: inline-block;
      margin: 0;
      cursor: pointer;
      margin-left: 34px;
      padding-right: 19px;
      padding-top: 9px;
      padding-bottom: 9px;
      transition: $transition;
      position: relative;

      @media (max-width: 1619.98px) {
        margin-left: 20px;
      }

      &:after {
        content: '';
        position: absolute;
        font-family: "icon";
        font-size: 10px;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
      }

      &:hover {
        color: $accent-color;
      }
    }

    .select-item {
      text-align: center;
      cursor: pointer;
      display: none;
      margin: 0;
      position: absolute;
      right: 0;
      background-color: #1c1c1c;

      li {
        list-style: none;
        background: #1c1c1c;
        width: 100%;
        text-align: right;
        padding-right: 19px;
        padding-bottom: 5px;
        margin-left: 0;
        transition: $transition;

        a {}

        &:hover {
          cursor: pointer;
          color: $accent-color;
        }
      }
    }

    .toggle {
      display: block !important;
      position: absolute;
      left: 0;
    }
  }

  &--floor {
    margin-right: 90px;

    @media (max-width: 1619.98px) {
      margin-right: 45px;
    }

    @media (max-width: 1299.98px) {
      margin-right: 25px;
    }

    &:last-child {
      @include media-breakpoint-down(md) {
        margin-right: 0;
      }

      @include media-breakpoint-down(xs) {
        border-right: none;
      }
    }

    @include media-breakpoint-down(xs) {
      max-width: 100%;
      width: 100%;
      border-right: 1px solid $wc;
      margin: 0;
    }

    .lang {
      color: $accent-color;
      padding: 0;
      white-space: nowrap;
      margin: 0;

      @include media-breakpoint-down(xs) {
        color: $wc;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:before {
        @include media-breakpoint-down(xs) {
          position: absolute;
          content: '';
          font-family: 'icon';
          border: 0;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          font-size: 20px;
          background-color: #1c1c1c;
        }
      }

      &:after {
        content: '';
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-10%);
        border: 4px solid transparent;
        border-top: 4px solid $accent-color;
        transition: $transition;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }

      &:hover {
        color: $wc;

        &:after {
          border-top: 4px solid $wc;
        }
      }
    }

    .select-item {
      min-width: 107px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 9px;

      @include media-breakpoint-down(xs) {
        padding: 0;
      }

      .active {
        @include media-breakpoint-down(xs) {
          background-color: #5A5A5A;
        }

        a {
          color: $wc;

          @include media-breakpoint-down(xs) {
            border-top: 1px solid $wc;
            border-bottom: 1px solid $wc;
          }
        }
      }

      li {
        text-align: center;
        padding-right: 0;

        @include media-breakpoint-down(xs) {
          padding: 0;
        }

        a {
          color: $accent-color;

          @include media-breakpoint-down(xs) {
            color: $wc;
            height: 40px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &:hover {
            color: $wc;
          }
        }


      }
    }

    .dropdown__select {
      width: 100%;
      max-width: 100%;
    }
  }
}




