.reservation__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-left: 60px;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    margin-left: 0;
  }

  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
    padding-top: 20px;
  }


}

.mobile-panel-control {
  display: none;
  border-top: 1px solid $accent-color;
  border-bottom: 1px solid $accent-color;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: center;
  padding: 0;

  @include media-breakpoint-down(md) {
    display: flex;
  }

  .authorization__authorized {
    border-right: 1px solid $accent-color;
    width: 100%;

    a {
      color: $accent-color;
      width: 100%;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dropdown {
      width: 100%;
    }

    .dropdown-menu {
      width: 100%;
      padding-right: 10px;
      margin-top: 1px;
    }



    .authorized-js {
      padding-right: 35px;

      &:after {
        font-size: 20px !important;
        right: 10px;
      }
    }
  }

  .dropdown__select {
    .lang {
      color: $accent-color;
      padding-right: 30px;
      margin-left: 15px;

      &:after {
        font-size: 20px !important;
      }
    }

    .select-item {
      border: 1px solid $accent-color;
      border-top: none;

      li {
        text-align: left;
        margin-left: 15px;
        padding-bottom: 1px;
      }
    }
  }


}